import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Row,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import "../Coupon/coupon.css";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { addHomePage, gethomepage } from "./HomePageApi";
import axios from "axios";
import { presignedApi } from "../Course/CourseFun";
import { UPLOAD_ENDPOINT } from "../../../ApiServices/Axios/Index";

function AddHomePage() {
  const [heroImage, setheroImage] = useState();
  const [form] = Form.useForm();
  const formRef = useRef(form);
  const [heroImageProgress, setheroImageProgress] = useState(0);
  const [iconCounterProgress, setIconCounterProgress] = useState(0);
  const [homePageData, sethomePageData] = useState({});
  function FetchHomePageDataList() {
    gethomepage()
      .then((response) => {
        if (response.status) {
          sethomePageData(response.data);
          form.setFieldsValue(response.data);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }
  useEffect(() => {
    FetchHomePageDataList();
  }, [form]);

  const createHomePage = (changedValues) => {
    let payload = {
      title: changedValues.title,
      heroText: changedValues.heroText,
      heroDescription: changedValues.heroDescription,
      mainHighlightTitle: changedValues.mainHighlightTitle,
      mainHighlightDescription: changedValues.mainHighlightDescription,
      consultingTitle: changedValues.consultingTitle,
      consultingDescription: changedValues.consultingDescription,
      consultationContact: changedValues.consultationContact,
      testimonialTitle: changedValues.testimonialTitle,
      testimonialDescription: changedValues.testimonialDescription,
      faqTitle: changedValues.faqTitle,
      faqDescription: changedValues.faqDescription,
      heroImage: changedValues.heroImage,
      metaTags: changedValues.metaTags,
      counters: changedValues.counters,
      subHighlights: changedValues.subHighlights,
      testimonials: changedValues.testimonials,
      faqs: changedValues.faqs,
    };

    addHomePage(payload, `67188e5ef7c51a8ff4a1dfe3`)
      .then((response) => {
        if (response.success) {
          message.success({ content: response.message });
          form.setFieldsValue(homePageData);
          FetchHomePageDataList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUploadCounter = async (event, index) => {
    try {
      const selectedFile = event.target.files[0];
      if (!selectedFile) {
        console.error("No file selected.");
        return;
      }
      const fileExtension = selectedFile.type.split("/").pop();
      const { preSignedUploadUrl, Key } = await presignedApi(
        `?mimetype=${fileExtension}`
      );

      if (preSignedUploadUrl) {
        await axios.put(preSignedUploadUrl, selectedFile, {
          headers: {
            "Content-Type": selectedFile.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setIconCounterProgress(percentCompleted);
          },
        });

        const counters = form.getFieldValue("counters") || [];
        counters[index].icon = Key;
        form.setFieldsValue({ counters });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleUploadHeroImage = async (event) => {
    try {
      const selectedFile = event.target.files[0];
      if (!selectedFile) {
        console.error("No file selected.");
        return;
      }

      const fileExtension = selectedFile.type.split("/").pop();
      const { preSignedUploadUrl, Key } = await presignedApi(
        `?mimetype=${fileExtension}`
      );

      if (preSignedUploadUrl) {
        await axios.put(preSignedUploadUrl, selectedFile, {
          headers: {
            "Content-Type": selectedFile.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setheroImageProgress(percentCompleted);
          },
        });

        setheroImage(Key);
        form.setFieldsValue({
          heroImage: Key,
        });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div>
      <Divider orientation="left" className="text-lg font-dmsans font-semibold">
        Update HomePage Data
      </Divider>
      <Form
        className="bg-white p-6 h-[900px] overflow-auto"
        layout="vertical"
        onFinishFailed={(err) => console.log(err)}
        onFinish={createHomePage}
        form={form}
        ref={formRef}
      >
        <Row className="grid grid-cols-1  gap-4">
          <Col>
            <Form.Item
              label={<p className="font-medium">Title</p>}
              name="title"
              rules={[
                { required: true, message: "Please enter Title" },
                {
                  min: 6,
                  message: "Title must be at least 4 characters",
                },
              ]}
            >
              <Input placeholder="Enter Title" />
            </Form.Item>
          </Col>{" "}
        </Row>
        <Row className="grid grid-cols-1 gap-4">
          <p className="font-medium">Counters</p>
          <Col>
            <Form.List
              name="counters"
              rules={[
                {
                  validator: async (_, counters) => {
                    if (!counters || counters.length < 1) {
                      return Promise.reject(
                        new Error("Please add at least one counter")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <div
                        key={key}
                        style={{ marginBottom: "16px" }}
                        className="grid grid-cols-1 lg:grid-cols-[1%_52%_18%_17%_6%] gap-4 "
                      >
                        <p className="font-medium"> {index + 1}•</p>

                        <Form.Item
                          {...restField}
                          name={[name, "title"]}
                          fieldKey={[fieldKey, "title"]}
                          rules={[
                            { required: true, message: "Please enter a title" },
                          ]}
                        >
                          <Input placeholder="Enter counter title" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "icon"]}
                          fieldKey={[fieldKey, "icon"]}
                          rules={[
                            {
                              required: true,
                              message: "Please upload an icon",
                            },
                          ]}
                        >
                          {form.getFieldValue(["counters", index, "icon"]) ? (
                            <div
                              className="grid grid-cols-1 sm:grid-cols-2"
                              direction="vertical"
                              size="large"
                            >
                              <label
                                htmlFor={`countericon-img-${index}`}
                                className="rounded-none border flex justify-center items-center h-[100px] aspect-video"
                              >
                                <div className="h-[100px] aspect-video d-flex justify-center items-center relative">
                                  <Image
                                    src={`${UPLOAD_ENDPOINT}${form.getFieldValue(
                                      ["counters", index, "icon"]
                                    )}`}
                                    className="h-[100px] object-cover aspect-video"
                                  />
                                  <button
                                    type="button"
                                    onClick={() =>
                                      document
                                        .getElementById(
                                          `countericon-img-${index}`
                                        )
                                        .click()
                                    }
                                    className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 text-white flex items-center justify-center text-sm font-medium hover:bg-opacity-70"
                                  >
                                    Change Image
                                  </button>
                                </div>
                              </label>
                              <div>
                                <Input
                                  type="file"
                                  id={`countericon-img-${index}`}
                                  style={{
                                    opacity: "0",
                                    position: "absolute",
                                    zIndex: "-1",
                                  }}
                                  onChange={(e) =>
                                    handleUploadCounter(e, index)
                                  }
                                  accept="image/*"
                                />
                              </div>
                            </div>
                          ) : (
                            <div
                              className="grid grid-cols-1 sm:grid-cols-2"
                              direction="vertical"
                              size="large"
                            >
                              <label
                                htmlFor={`countericon-img-${index}`}
                                className="rounded-none border flex justify-center items-center h-[100px] aspect-video"
                              >
                                <div className="rounded-none border flex justify-center items-center h-[100px] aspect-video">
                                  {iconCounterProgress > 0 &&
                                  iconCounterProgress < 100 ? (
                                    <div className="animate-pulse h-[100px] aspect-video">
                                      <div
                                        className={`bg-slate-300 h-[100px] flex flex-row justify-center items-center`}
                                        style={{
                                          width: `${iconCounterProgress}%`,
                                        }}
                                      >
                                        <p>{`${iconCounterProgress}%`}</p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="flex justify-center items-center m-2">
                                        <UploadOutlined />
                                      </p>
                                      <p>Select image</p>
                                    </div>
                                  )}
                                </div>
                              </label>
                              <div>
                                <Input
                                  type="file"
                                  id={`countericon-img-${index}`}
                                  style={{
                                    opacity: "0",
                                    position: "absolute",
                                    zIndex: "-1",
                                  }}
                                  onChange={(e) =>
                                    handleUploadCounter(e, index)
                                  }
                                  accept="image/*"
                                />
                              </div>
                            </div>
                          )}
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "count"]}
                          fieldKey={[fieldKey, "count"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter the count",
                            },
                            {
                              type: "number",
                              message: "Count must be a number",
                            },
                          ]}
                        >
                          <InputNumber placeholder="Enter count" />
                        </Form.Item>

                        <Button
                          onClick={() => remove(name)}
                          className="bg-red-500 text-xs text-white border-none rounded-none"
                        >
                          Remove
                        </Button>
                      </div>
                    )
                  )}
                  <Form.Item>
                    <Button
                      className="bg-orange text-xs text-white w-1/2 rounded-none"
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Counter
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Row className="grid grid-cols-1 gap-4">
          <p className="font-medium">Meta Tags</p>
          <Col>
            <Form.List
              name="metaTags"
              rules={[
                {
                  validator: async (_, metaTags) => {
                    if (!metaTags || metaTags.length < 1) {
                      return Promise.reject(
                        new Error("Please add at least one meta tag")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <div
                        key={key}
                        style={{ marginBottom: "16px" }}
                        className="grid grid-cols-1 sm:grid-cols-[1%_47%_40%_8%] gap-4"
                      >
                        <p className="font-medium ">{index + 1}•</p>

                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          fieldKey={[fieldKey, "name"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter meta tag name",
                            },
                          ]}
                        >
                          <Input placeholder="Enter meta tag name (e.g., description, keywords)" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "content"]}
                          fieldKey={[fieldKey, "content"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter meta tag content",
                            },
                          ]}
                        >
                          <Input.TextArea placeholder="Enter meta tag content" />
                        </Form.Item>

                        <Button
                          onClick={() => remove(name)}
                          className="bg-red-500 text-xs text-white border-none rounded-none"
                        >
                          Remove
                        </Button>
                      </div>
                    )
                  )}

                  <Form.Item>
                    <Button
                      className="bg-orange text-xs text-white w-1/2 rounded-none"
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Meta Tag
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Row className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <Col>
            <Form.Item
              label={<p className="font-medium">Hero Text</p>}
              name="heroText"
              rules={[
                { required: true, message: "Please enter Hero Text" },
                {
                  min: 6,
                  message: "Hero Text must be at least 4 characters",
                },
              ]}
            >
              <Input placeholder="Enter Hero Text" />
            </Form.Item>
          </Col>{" "}
          <Col>
            <Form.Item
              label={<p className="font-medium">Hero Description</p>}
              name="heroDescription"
              rules={[
                { required: true, message: "Please enter Hero Description" },
                {
                  min: 6,
                  message: "Hero Description must be at least 4 characters",
                },
              ]}
            >
              <Input placeholder="Enter Hero Description" />
            </Form.Item>
          </Col>{" "}
          <Col>
            <Form.Item
              label={<p className="font-medium">Hero Image</p>}
              name="heroImage"
              rules={[{ required: true, message: "Please upload hero image" }]}
            >
              {form.getFieldValue("heroImage") || heroImage ? (
                <div
                  className="grid grid-cols-1 sm:grid-cols-2"
                  direction="vertical"
                  size="large"
                >
                  <label
                    htmlFor="hero-img"
                    className="rounded-none border flex justify-center items-center h-[100px] aspect-video"
                  >
                    <div className="h-[100px] aspect-video d-flex justify-center items-center relative">
                      <Image
                        src={`${UPLOAD_ENDPOINT}${form.getFieldValue(
                          "heroImage"
                        )}`}
                        autoPlay={false}
                        className="h-[100px] object-cover aspect-video"
                      >
                        Your browser does not support the Image tag.
                      </Image>
                      <button
                        type="button"
                        onClick={() =>
                          document.getElementById("hero-img").click()
                        }
                        className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 text-white flex items-center justify-center text-sm font-medium hover:bg-opacity-70"
                      >
                        Change Image
                      </button>
                    </div>
                  </label>
                  <div>
                    <Input
                      type="file"
                      id="hero-img"
                      style={{
                        opacity: "0",
                        position: "absolute",
                        zIndex: "-1",
                      }}
                      onChange={(e) => handleUploadHeroImage(e)}
                      accept="image/*"
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="grid grid-cols-1 sm:grid-cols-2"
                  direction="vertical"
                  size="large"
                >
                  <label
                    htmlFor="hero-img"
                    className="rounded-none border flex justify-center items-center h-[100px] aspect-video"
                  >
                    <div className="rounded-none border flex justify-center items-center h-[100px] aspect-video">
                      {heroImageProgress > 0 && heroImageProgress < 100 ? (
                        <div className="animate-pulse h-[100px] aspect-video">
                          <div
                            className={`bg-slate-300 h-[100px] flex flex-row justify-center items-center`}
                            style={{
                              width: `${heroImageProgress}%`,
                            }}
                          >
                            <p>{`${heroImageProgress}%`}</p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <p className="flex justify-center items-center m-2 ">
                            <UploadOutlined />
                          </p>
                          <p>Select image</p>
                        </div>
                      )}
                    </div>
                  </label>
                  <div>
                    <Input
                      type="file"
                      id="hero-img"
                      style={{
                        opacity: "0",
                        position: "absolute",
                        zIndex: "-1",
                      }}
                      onChange={(e) => handleUploadHeroImage(e)}
                      accept="image/*"
                    />
                  </div>
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Col>
            <Form.Item
              label={<p className="font-medium">MainHighlight Title</p>}
              name="mainHighlightTitle"
              rules={[
                { required: true, message: "Please enter MainHighlight Title" },
                {
                  min: 6,
                  message: "MainHighlight Title must be at least 4 characters",
                },
              ]}
            >
              <Input placeholder="Enter MainHighlight Title" />
            </Form.Item>
          </Col>{" "}
          <Col>
            <Form.Item
              label={<p className="font-medium">MainHighlight Description</p>}
              name="mainHighlightDescription"
              rules={[
                {
                  required: true,
                  message: "Please enter MainHighlight Description",
                },
                {
                  min: 6,
                  message:
                    "MainHighlight Description must be at least 40 characters",
                },
              ]}
            >
              <Input placeholder="Enter MainHighlight Description" />
            </Form.Item>
          </Col>{" "}
        </Row>
        <Row className="grid grid-cols-1 gap-4">
          <p className="font-medium">SubHighlights</p>
          <Col>
            <Form.List
              name="subHighlights"
              rules={[
                {
                  validator: async (_, subHighlights) => {
                    if (!subHighlights || subHighlights.length < 1) {
                      return Promise.reject(
                        new Error("Please add at least one subHighlight")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <div
                        key={key}
                        style={{ marginBottom: "16px" }}
                        className="grid grid-cols-1 sm:grid-cols-[1%_47%_40%_8%] gap-4"
                      >
                        <p className="font-medium "> {index + 1}•</p>

                        <Form.Item
                          {...restField}
                          name={[name, "title"]}
                          fieldKey={[fieldKey, "title"]}
                          rules={[
                            { required: true, message: "Please enter a title" },
                          ]}
                        >
                          <Input placeholder="Enter title" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          fieldKey={[fieldKey, "description"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter a description",
                            },
                          ]}
                        >
                          <Input.TextArea placeholder="Enter description" />
                        </Form.Item>

                        <Button
                          onClick={() => remove(name)}
                          className="bg-red-500 text-xs text-white border-none rounded-none"
                        >
                          Remove
                        </Button>
                      </div>
                    )
                  )}

                  <Form.Item>
                    <Button
                      className="bg-orange text-xs text-white w-1/2 rounded-none"
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add SubHighlight
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Row className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <Col>
            <Form.Item
              label={<p className="font-medium">Consulting Title</p>}
              name="consultingTitle"
              rules={[
                { required: true, message: "Please enter Consulting Title" },
                {
                  min: 6,
                  message: "Consulting Title must be at least 4 characters",
                },
              ]}
            >
              <Input placeholder="Enter Consulting Title" />
            </Form.Item>
          </Col>{" "}
          <Col>
            <Form.Item
              label={<p className="font-medium">Consulting Description</p>}
              name="consultingDescription"
              rules={[
                {
                  required: true,
                  message: "Please enter Consulting Description",
                },
                {
                  min: 6,
                  message:
                    "Consulting Description must be at least 4 characters",
                },
              ]}
            >
              <Input placeholder="Enter Consulting Description" />
            </Form.Item>
          </Col>{" "}
          <Col>
            <Form.Item
              label={<p className="font-medium">Consultation Contact</p>}
              name="consultationContact"
              rules={[
                {
                  required: true,
                  message: "Please enter a Consultation Contact",
                },
              ]}
            >
              <Input placeholder="Enter Consultation Contact" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Col>
            <Form.Item
              label={<p className="font-medium">Testimonial Title</p>}
              name="testimonialTitle"
              rules={[
                { required: true, message: "Please enter Testimonial Title" },
                {
                  min: 6,
                  message: " Testimonial Title must be at least 4 characters",
                },
              ]}
            >
              <Input placeholder="Enter Title" />
            </Form.Item>
          </Col>{" "}
          <Col>
            <Form.Item
              label={<p className="font-medium">Testimonial Description</p>}
              name="testimonialDescription"
              rules={[
                { required: true, message: "Please enter Title" },
                {
                  min: 6,
                  message: "Title must be at least 4 characters",
                },
              ]}
            >
              <Input placeholder="Enter Testimonial Description" />
            </Form.Item>
          </Col>{" "}
        </Row>
        <Row className="grid grid-cols-1">
          <p className="font-medium">Testimonials</p>
          <Col>
            <Form.List
              name="testimonials"
              rules={[
                {
                  validator: async (_, testimonials) => {
                    if (!testimonials || testimonials.length < 1) {
                      return Promise.reject(
                        new Error("Please add at least one testimonial")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <div
                        key={key}
                        style={{ marginBottom: "16px" }}
                        className="grid grid-cols-1 sm:grid-cols-[1%_47%_33%_8%_5%] gap-4"
                      >
                        <p className="font-medium "> {index + 1}•</p>

                        <Form.Item
                          {...restField}
                          name={[name, "detail"]}
                          fieldKey={[fieldKey, "detail"]}
                          rules={[
                            { required: true, message: "Please enter details" },
                          ]}
                        >
                          <Input.TextArea placeholder="Enter testimonial details" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          fieldKey={[fieldKey, "name"]}
                          rules={[
                            { required: true, message: "Please enter a name" },
                          ]}
                        >
                          <Input placeholder="Enter name" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "stars"]}
                          fieldKey={[fieldKey, "stars"]}
                          rules={[
                            {
                              required: true,
                              type: "number",
                              min: 1,
                              max: 5,
                              message:
                                "Please provide a rating between 1 and 5",
                            },
                          ]}
                        >
                          <InputNumber
                            min={1}
                            max={5}
                            placeholder="Stars (1-5)"
                            className="rounded-none"
                          />
                        </Form.Item>

                        <Button
                          onClick={() => remove(name)}
                          className="bg-red-500 text-xs text-white border-none rounded-none"
                        >
                          Remove
                        </Button>
                      </div>
                    )
                  )}

                  <Form.Item>
                    <Button
                      className="bg-orange text-xs text-white  rounded-none"
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Testimonial
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        <Row className="grid grid-cols-1 sm:grid-cols-2  gap-4">
          <Col>
            <Form.Item
              label={<p className="font-medium">FAQ Title</p>}
              name="faqTitle"
              rules={[
                { required: true, message: "Please enter FAQ Title" },
                {
                  min: 6,
                  message: "FAQ Title must be at least 4 characters",
                },
              ]}
            >
              <Input placeholder="Enter FAQ Title" />
            </Form.Item>
          </Col>{" "}
          <Col>
            <Form.Item
              label={<p className="font-medium">FAQ Description</p>}
              name="faqDescription"
              rules={[
                { required: true, message: "Please enter FAQ Description" },
                {
                  min: 6,
                  message: "FAQ Description must be at least 4 characters",
                },
              ]}
            >
              <Input placeholder="Enter FAQ Description" />
            </Form.Item>
          </Col>{" "}
        </Row>
        <Row className="grid grid-cols-1">
          <p className="font-medium">FAQs</p>
          <Col>
            <Form.List
              name="faqs"
              rules={[
                {
                  validator: async (_, faqs) => {
                    if (!faqs || faqs.length < 1) {
                      return Promise.reject(
                        new Error("Please add at least one FAQ")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <div
                        key={key}
                        style={{ marginBottom: "16px" }}
                        className="grid grid-cols-1 sm:grid-cols-[10%_33%_33%_20%]  gap-4"
                      >
                        <p className="font-medium ">{index + 1}•</p>

                        <Form.Item
                          {...restField}
                          name={[name, "q"]}
                          fieldKey={[fieldKey, "q"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter a question",
                            },
                          ]}
                        >
                          <Input placeholder="Enter question" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "a"]}
                          fieldKey={[fieldKey, "a"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter an answer",
                            },
                          ]}
                        >
                          <Input placeholder="Enter answer" />
                        </Form.Item>

                        <Button
                          onClick={() => remove(name)}
                          className="bg-red-500 text-xs text-white border-none rounded-none"
                        >
                          Remove FAQ
                        </Button>
                      </div>
                    )
                  )}

                  <Form.Item>
                    <Button
                      className="bg-orange text-xs text-white  rounded-none"
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add FAQ
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        <Row className="flex justify-between">
          <Form.Item>
            <Button
              className="rounded-none p-[0px_32px_0px_32px]   text-sm font-dmsans font-semibold text-gray2 "
              size="small"
              onClick={() => form.resetFields()}
            >
              Cancel
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              className="rounded-none p-[0px_32px_0px_32px] hover:bg-black text-white font-dmsans font-semibold  bg-orange text-xs "
              size="small"
              htmlType="submit"
            >
              Create HomePage
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}
export default AddHomePage;
