import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import "../Coupon/coupon.css";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { presignedApi } from "../Course/CourseFun";
import { UPLOAD_ENDPOINT } from "../../../ApiServices/Axios/Index";
import { addEvent, updateEvent } from "./EventApi";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { dispatchtoast } from "../../../Utils";

function AddEvent() {
  const [uploadImageProgress, setUploadImageProgress] = useState(0);
  const [form] = Form.useForm();
  const formRef = useRef(form);
  const navigate = useNavigate();
  const location = useLocation();
  const fill = location.state?.fill;
  const from = location.state?.from;
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categorySlug, setCategorySlug] = useState("");
  const [metaContent, setMetaContent] = useState("");

  const generateSlug = (value) => {
    return value
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
  };

  const handleTitleChange = (e) => {
    const inputValue = e.target.value;

    let slugValue = form.getFieldValue("slug"); 
    if (from !== "edit") {
      slugValue = generateSlug(inputValue); 
    }

    const updatedMetaTags = form.getFieldValue("metaTags") || [];
    if (updatedMetaTags.length > 0) {
      updatedMetaTags[0].content = inputValue;
    } else {
      updatedMetaTags.push({ name: "title", content: inputValue });
    }

    form.setFieldsValue({
      slug: slugValue,
      metaTags: updatedMetaTags,
    });

    setTitle(inputValue);
    setSlug(slugValue);
    setMetaContent(inputValue);
  };

  const generateCategorySlug = (value) => {
    return value
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
  };

  const handleCategoryNameChange = (e) => {
    const inputValue = e.target.value;
    form.setFieldsValue({ categorySlug });

    setCategoryName(inputValue);
    setCategorySlug(generateCategorySlug(inputValue));
  };
  const [venueType, setVenueType] = useState(
    !fill
      ? null
      : fill.venue === "google" || fill.venue === "zoom"
      ? "online"
      : "offline"
  );
  const [platform, setPlatform] = useState(null);

  useEffect(() => {
    if (fill) {
      if (fill.venue.includes("google")) {
        setPlatform("google");
        setVenueType("online");
      } else if (fill.venue.includes("zoom")) {
        setPlatform("zoom");
        setVenueType("online");
      } else {
        setPlatform(null);
        setVenueType("offline");
      }
    }
  }, [fill]);
  const handleVenueChange = (value) => {
    setVenueType(value);
    setPlatform("");
  };
  const handlePlatformChange = (value) => {
    setPlatform(value);
  };
  const startTime = moment(fill?.startTime).isValid()
    ? moment(fill.startTime)
    : null;
  const endTime = moment(fill?.endTime).isValid() ? moment(fill.endTime) : null;
  const [coverImage, setCoverImage] = useState(fill?.coverImage ?? "");
  const { Option } = Select;
  const handleUpload = async (event, type) => {
    try {
      const selectedFile = event.target.files[0];
      if (!selectedFile) {
        console.error("No file selected.");
        return;
      }
      const fileExtension = selectedFile.type.split("/").pop();
      const { preSignedUploadUrl, Key } = await presignedApi(
        `?mimetype=${fileExtension}`
      );

      if (preSignedUploadUrl) {
        await axios.put(preSignedUploadUrl, selectedFile, {
          headers: {
            "Content-Type": selectedFile.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setUploadImageProgress(percentCompleted);
          },
        });
        setCoverImage(Key);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const createEvent = (changedValues) => {
    let payload = {
      title: changedValues.title,
      subtitle: changedValues.subtitle,
      slug: changedValues.slug,
      description: changedValues.description,
      venue: changedValues.venue,
      startTime: changedValues.startTime,
      endTime: changedValues.endTime,
      coverImage: coverImage,
      eventLink: changedValues.eventLink,
      categoryName: changedValues.categoryName,
      metaTags: changedValues.metaTags,
      categorySlug: changedValues.categorySlug,
    };
    {
      from === "edit"
        ? updateEvent(payload, `${fill._id}`)
            .then((response) => {
              if (response.status) {
                message.success({ content: response.message });
                navigate("/event");
                form.resetFields();
                setCoverImage(null);
                setUploadImageProgress(0);
                document.getElementById("cover-image").value = null;
              }
            })
            .catch((error) => {
              console.log({ error });
            })
        : addEvent(payload)
            .then((response) => {
              if (response.status) {
                message.success({ content: response.message });
                navigate("/event");
                form.resetFields();
                setCoverImage(null);
                setUploadImageProgress(0);
                document.getElementById("cover-image").value = null;
              }
            })
            .catch((error) => {
              console.log({ error });
            });
    }
  };
  const metaTagOptions = [
    "description",
    "title",
    "keywords",
    "robots",
    "author",
  ];
  return (
    <div>
      <Divider orientation="left" className="text-lg font-dmsans font-semibold">
        {from === "edit" ? "Update" : "Add"} Event
      </Divider>
      <Form
        className="bg-white p-6"
        layout="vertical"
        onFinishFailed={(error) =>
          dispatchtoast(
            error?.errorFields?.[0]?.errors?.[0] || "Missing Required Fields",
            true
          )
        }
        onFinish={createEvent}
        form={form}
        ref={formRef}
        initialValues={
          from === "edit"
            ? {
                title: fill.title,
                subtitle: fill.subtitle,
                slug: fill.slug,
                categoryName: fill.categoryName,
                eventLink: fill.eventLink,
                coverImage: fill.coverImage,
                metaTags: fill.metaTags || [{ name: "", content: "" }],
                topic: fill.topic,
                startTime,
                endTime,
                description: fill.description,
                venuetype:
                  fill.venue.includes("google") || fill.venue.includes("zoom")
                    ? "online"
                    : "offline",
                platformType: fill.venue.includes("google") ? "google" : "zoom",
                venue: fill.venue,
                categorySlug: fill.categorySlug,
              }
            : {
                metaTags: [{ name: "Title", content: "" }],
              }
        }
      >
        <Row className="grid grid-cols-1 sm:grid-cols-2  gap-4">
          <Col>
            <Form.Item
              label={<p className="font-medium">Title</p>}
              name="title"
              rules={[
                { required: true, message: "Please enter Title" },
                {
                  min: 6,
                  message: "Title must be at least 4 characters",
                },
              ]}
            >
              <Input
                placeholder="Enter Title"
                value={title}
                onChange={handleTitleChange}
              />
            </Form.Item>
          </Col>{" "}
          <Col>
            <Form.Item
              label={<p className="font-medium">SubTitle</p>}
              name="subtitle"
            >
              <Input placeholder="Enter SubTitle" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="grid grid-cols-1 sm:grid-cols-2  gap-4">
          <Col>
            <Form.Item
              label={<p className="font-medium">Slug</p>}
              name="slug"
              rules={[
                { required: true, message: "Please enter Slug" },
                {
                  min: 6,
                  message: "Slug must be at least 6 characters",
                },
                {
                  pattern: /^[a-zA-Z0-9-]+$/,
                  message:
                    "Slug can only contain letters, numbers, and hyphens",
                },
              ]}
            >
              <Input
                placeholder="Enter Slug"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                disabled={from === "edit" ? true : false}
              />
            </Form.Item>
          </Col>{" "}
          <Col>
            <Form.Item
              label={<p className="font-medium">Event Link</p>}
              name="eventLink"
              rules={[
                { required: true, message: "Please enter a Event Link" },
                { type: "url", message: "Please enter a valid Event Link" },
              ]}
            >
              <Input placeholder="Enter Event Link" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Col>
            <Form.Item
              label={<p className="font-medium">Category Name</p>}
              name="categoryName"
              rules={[
                { required: true, message: "Please enter a Category Name" },
                {
                  type: "string",
                  message: "Please enter a valid Category Name",
                },
              ]}
            >
              <Input
                placeholder="Enter Category Name"
                value={categoryName}
                onChange={handleCategoryNameChange}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label={<p className="font-medium">Category Slug</p>}
              name="categorySlug"
              rules={[
                {
                  min: 6,
                  message: "Category Slug must be at least 6 characters",
                },
                {
                  pattern: /^[a-zA-Z0-9-]+$/,
                  message:
                    "Category Slug can only contain letters, numbers, and hyphens",
                },
              ]}
            >
              <Input
                placeholder="Enter Category Slug"
                value={categorySlug}
                onChange={(e) => setCategorySlug(e.target.value)}
              />
            </Form.Item>
          </Col>{" "}
        </Row>

        <Row className="grid grid-cols-1 gap-4">
          <p className="font-medium">Meta Tags</p>
          <Col>
            <Form.Item
              validateTrigger="onSubmit"
              style={{ marginBottom: "16px" }}
            >
              <Form.List
                name="metaTags"
                rules={[
                  {
                    validator: async (_, metaTags) => {
                      if (!metaTags || metaTags.length < 1) {
                        return Promise.reject(
                          new Error("Please add at least one tag")
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => {
                        const currentTags =
                          form
                            .getFieldValue("metaTags")
                            ?.map((tag) => tag?.name) || [];

                        const availableTags = metaTagOptions;

                        return (
                          <div
                            key={key}
                            style={{ marginBottom: "16px" }}
                            className="grid grid-cols-1 sm:grid-cols-[1%_47%_40%_8%] gap-4"
                          >
                            <p className="font-medium">{index + 1}•</p>

                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              fieldKey={[fieldKey, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a meta tag name",
                                },
                              ]}
                            >
                              {index === 0 ? (
                                <Input value="title" disabled />
                              ) : (
                                <Select
                                  placeholder="Select a tag"
                                  disabled={index === 0}
                                >
                                  {availableTags.slice(0, 5).map((option) => (
                                    <Option key={option} value={option}>
                                      {option.charAt(0).toUpperCase() +
                                        option.slice(1)}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "content"]}
                              fieldKey={[fieldKey, "content"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter meta tag content",
                                },
                              ]}
                            >
                              {index === 0 ? (
                                <Input.TextArea
                                  placeholder="Enter meta tag content"
                                  value={metaContent}
                                  onChange={(e) =>
                                    setMetaContent(e.target.value)
                                  }
                                />
                              ) : (
                                <Input.TextArea placeholder="Enter meta tag content" />
                              )}
                            </Form.Item>

                            <Button
                              onClick={() => remove(name)}
                              disabled={index === 0 || fields.length === 1}
                              className="bg-red-500 text-xs text-white border-none rounded-none"
                            >
                              Remove
                            </Button>
                          </div>
                        );
                      }
                    )}

                    <Form.Item>
                      <Button
                        className="bg-orange text-xs text-white w-1/2 rounded-none"
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Meta Tag
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Col>
        </Row>
        <Row className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Col>
            <Form.Item
              label={<p className="font-medium">Start Time</p>}
              name="startTime"
              rules={[
                { required: true, message: "Please select a Start Date " },
              ]}
            >
              <DatePicker
                placeholder="Select Start Date"
                style={{ width: "100%", borderRadius: "0%" }}
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label={<p className="font-medium">End Time</p>}
              name="endTime"
              dependencies={["startTime"]}
              rules={[{ required: true, message: "Please select a End Date " }]}
            >
              <DatePicker
                placeholder="Select End Date"
                style={{ width: "100%", borderRadius: "0%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Col>
            <Form.Item
              label={<p className="font-medium">Description</p>}
              name="description"
              rules={[
                { required: true, message: "Please enter Description" },
                {
                  min: 6,
                  message: "Description must be at least 40 characters",
                },
              ]}
            >
              <Input placeholder="Enter Description" />
            </Form.Item>
          </Col>{" "}
          <Col>
            <Form.Item
              label={<p className="font-medium">Venue</p>}
              name="venuetype"
              rules={[{ required: true, message: "Please select a Venue" }]}
            >
              <Select placeholder="Select Venue" onChange={handleVenueChange}>
                {["online", "offline"].map((option) => (
                  <Option key={option} value={option}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>{" "}
          {venueType === "online" && (
            <>
              <Col>
                <Form.Item
                  label={<p className="font-medium">Platform</p>}
                  name="platformType"
                  rules={[
                    { required: true, message: "Please select a platform" },
                  ]}
                >
                  <Select
                    placeholder="Select Platform"
                    onChange={handlePlatformChange}
                  >
                    {["google", "zoom"].map((option) => (
                      <Option key={option} value={option}>
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {["google", "zoom"].includes(platform) && (
                <Col>
                  <Form.Item
                    label={<p className="font-medium">Meeting Link</p>}
                    name="venue"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the meeting link",
                      },
                      {
                        type: "url",
                        message: "Please enter a valid URL",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Meeting Link" />
                  </Form.Item>
                </Col>
              )}
            </>
          )}
          {venueType === "offline" && (
            <Col>
              <Form.Item
                label={<p className="font-medium">Address</p>}
                name="venue"
                rules={[
                  { required: true, message: "Please enter the address" },
                  {
                    min: 6,
                    message: "Address must be at least 6 characters",
                  },
                ]}
              >
                <Input.TextArea placeholder="Enter Address" />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row className="grid grid-cols-1 gap-4">
          <Col>
            <Form.Item
              name="coverImage"
              label={<p className="font-medium">Cover image</p>}
            >
              {coverImage || fill.coverImage ? (
                <div
                  className="grid grid-cols-1 sm:grid-cols-2"
                  direction="vertical"
                  size="large"
                >
                  <label
                    htmlFor="cover-image"
                    className="rounded-none border flex justify-center items-center h-[100px] aspect-video"
                  >
                    <div className="h-[100px] aspect-video d-flex justify-center items-center relative">
                      <Image
                        src={`${UPLOAD_ENDPOINT}${coverImage}`}
                        className="h-[100px] object-cover aspect-video"
                        height={100}
                        width={180}
                      >
                        Your browser does not support the Image tag.
                      </Image>
                      <button
                        type="button"
                        onClick={() =>
                          document.getElementById("cover-image").click()
                        }
                        className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 text-white flex items-center justify-center text-sm font-medium hover:bg-opacity-70"
                      >
                        Change Image
                      </button>
                    </div>
                  </label>
                  <div>
                    <Input
                      type="file"
                      id="cover-image"
                      style={{
                        opacity: "0",
                        position: "absolute",
                        zIndex: "-1",
                      }}
                      onChange={(e) => handleUpload(e)}
                      accept="image/*"
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="grid grid-cols-1 sm:grid-cols-2"
                  direction="vertical"
                  size="large"
                >
                  <label
                    htmlFor="cover-image"
                    className="rounded-none border flex justify-center items-center h-[100px] aspect-video"
                  >
                    <div className="rounded-none border flex justify-center items-center h-[100px] aspect-video">
                      {uploadImageProgress > 0 && uploadImageProgress < 100 ? (
                        <div className="animate-pulse h-[100px] aspect-video">
                          <div
                            className={`bg-slate-300 h-[100px] flex flex-row justify-center items-center`}
                            style={{ width: `${uploadImageProgress}%` }}
                          >
                            <p>{`${uploadImageProgress}%`}</p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <p className="flex justify-center items-center m-2 ">
                            <UploadOutlined />
                          </p>
                          <p>Select image</p>
                        </div>
                      )}
                    </div>
                  </label>
                  <div>
                    <Input
                      type="file"
                      id="cover-image"
                      style={{
                        opacity: "0",
                        position: "absolute",
                        zIndex: "-1",
                      }}
                      onChange={(e) => handleUpload(e)}
                      accept="image/*"
                    />
                  </div>
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row className="flex justify-between">
          <Form.Item>
            <Button
              className="rounded-none p-[0px_32px_0px_32px]   text-sm font-dmsans font-semibold text-gray2 "
              size="small"
              onClick={() => {
                from === "add" ? form.resetFields() : navigate("/event");
              }}
            >
              Cancel
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              className="rounded-none p-[0px_32px_0px_32px] hover:bg-black text-white font-dmsans font-semibold  bg-orange text-xs "
              size="small"
              htmlType="submit"
            >
              {from === "edit" ? "Update" : "Create"} Event
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}
export default AddEvent;
