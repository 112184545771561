import React, { useEffect, useState } from "react";
import { Badge, Layout, Menu } from "antd";
import { Images } from "../ImageController/Index";
import { LogoutMenuItem, MenuItems } from "./MenuItems";
import { GoBell, GoHeart } from "react-icons/go";
import { CiUser } from "react-icons/ci";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Header, Sider, Content } = Layout;
const ProjectLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [windowwidth, setwindowwidth] = useState(window.innerWidth);
  const [showDefault, setShowDefault] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setwindowwidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (windowwidth > 1000) {
      setCollapsed(false);
      setShowDefault(true);
    } else {
      setCollapsed(true);
      setShowDefault(false);
    }
  }, [windowwidth]);
  function logout() {
    localStorage.clear();
    navigate("/");
  }
  return (
    <>
      <Layout hasSider className="min-h-screen sidebar">
        <Sider
          style={{
            position: "fixed",
            marginBottom: "20px",
            overflowY: "scroll",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
          trigger={null}
          collapsible
          className=""
          collapsed={collapsed}
        >
          <div className="demo-logo-vertical flex justify-around items-center my-5">
            {!collapsed ? (
              <>
                <div className="flex gap-4 justify-start items-center">
                  <img
                    src={Images.FullLogo}
                    className=" w-[75%] ml-5"
                    alt="Weblogo"
                  />
                </div>
              </>
            ) : (
              <img src={Images.Logo} alt="WeblogoSmall" className="h-8 w-8" />
            )}
          </div>

          <div style={{ flexGrow: 1 }}>
            <Menu theme="dark" mode="inline" items={MenuItems()} />
          </div>

          {/* Logout Menu Item at the Bottom */}
          <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
            <Menu theme="dark" mode="inline" items={LogoutMenuItem()} />
          </div>
        </Sider>

        <Layout>
          <Content
            className={`bg-white h-full p-5 md:p-8
                 ${collapsed ? "ml-[25px]" : "ml-[200px]"}
            `}
          >
            {/* <div className="mb-5 w-full flex justify-between items-center gap-4">
              <BsArrowLeft
                size={20}
                onClick={() => navigate(-1)}
                className="cursor-pointer"
              />
            </div> */}

            {children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default ProjectLayout;
