import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Collapse, Rate, Skeleton } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../../ImageController/Index";
import { GoDotFill } from "react-icons/go";
import { UPLOAD_ENDPOINT } from "../../../ApiServices/Axios/Index";
import { convertSeconds } from "../../../Utils";

const CourseDetail = () => {
  const location = useLocation();
  const viewcourse = location.state?.course;
  const [skeleton, setSkeleton] = useState(false);
  const [installment, setInstallment] = useState(false);

  const getTopics = (data) => {
    return data?.map((i, index) => {
      return {
        key: i._id,
        label: <p className="font-bold font-inter">{i?.title}</p>,
        children:
          i.sections?.length > 0 ? (
            <Collapse
              className="nestedCollaps p-2"
              items={getTopics(i?.sections)}
            />
          ) : (
            <div className="p-2">
              <p className="font-semibold font-inter mt-4">Lectures</p>
              <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-x-3 mt-2">
                {i?.lectures?.map((lecture, index) => {
                  return (
                    <div className="w-full">
                      <video
                        preload="metadata"
                        poster={lecture?.thumbnail}
                        className="rounded-md"
                        controls
                      >
                        {" "}
                        <source
                          src={`${UPLOAD_ENDPOINT}${lecture.link}`}
                          type="video/mp4"
                        />
                      </video>
                      <p className="font-inter font-semibold mt-2">
                        {lecture?.title}
                      </p>
                    </div>
                  );
                })}
              </div>
              <hr className="mt-4" />
              <div>
                <p className="font-semibold font-inter mt-4">Documents</p>
                <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-x-3 mt-2">
                  {i?.docs?.map((doc, index) => {
                    return (
                      <div key={index} className="w-full">
                        <a
                          rel="noreferrer"
                          download
                          target="_blank"
                          className="font-bold text-blue-400 cursor-pointer"
                          href={`${UPLOAD_ENDPOINT}${doc.link}`}
                        >
                          {" "}
                          {doc?.title}
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="mt-4" />
              <div>
                <p className="font-semibold font-inter mt-4">Youtube Videos</p>
                <div className="grid grid-cols-1 md:grid-cols-2 place-items-start gap-x-3 mt-2">
                  {i?.ytLinks?.map((yt, index) => {
                    return (
                      <div className="w-full">
                        <iframe
                          className="w-3/4 h-full rounded-lg object-contain"
                          src={`https://www.youtube.com/embed/${yt.link}`}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="Embedded youtube"
                        />
                        <p className="font-inter font-semibold mt-2">
                          {yt?.title}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ),
        extra: (
          <p className="text-[#334155] text-xs flex justify-end items-center font-semibold">
            {i?.sections?.length > 0 ? i?.sections?.length : ""}{" "}
            {i?.sections?.length > 0 ? "Lessons" : ""}
          </p>
        ),
      };
    });
  };

  return (
    <>
      {skeleton ? (
        <div>
          <Skeleton />
          <Skeleton className="mt-4" />
          <Skeleton className="mt-4" />
        </div>
      ) : (
        <div className="grid grid-cols-10 place-items-start gap-x-4 p-4">
          <div className="col-span-10 md:col-span-6 font-inter w-full">
            <p className="text-2xl font-bold text-primary">
              Title: {viewcourse?.title ?? ""}
            </p>
            <p className="text-lg text-[#0F172A] leading-6 mt-2">
              Subtitle: {viewcourse?.subtitle ?? ""}
            </p>
            <div className="bg-sidebarbg text-white font-inter text-xs rounded-2xl p-5 mt-4">
              <div
                dangerouslySetInnerHTML={{ __html: viewcourse?.description }}
                className="leading-6"
              >
                {/* Descriotion: {viewcourse?.description} */}
              </div>
              <div className="grid mt-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-start gap-4">
                <p>Category: {viewcourse?.category}</p>
                <p>Topic: {viewcourse?.topic}</p>
                <p>Language: {viewcourse?.language}</p>
                <p>Subtitle Language: {viewcourse?.subtitleLanguage}</p>
                <p>Level: {viewcourse?.level}</p>
                <p>Duration: {convertSeconds(viewcourse?.lectureDuration)}</p>

                <p className="flex justify-center items-center gap-x-3">
                  Rating:{" "}
                  <span className="text-yellow-500">
                    {viewcourse?.averageRating ?? 0}
                  </span>
                  <span>
                    <Rate
                      style={{ fontSize: "13px" }}
                      value={viewcourse?.averageRating ?? 0}
                    />
                  </span>
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 font-inter md:grid-cols-2 lg:grid-cols-3 place-items-center mt-4 gap-4">
              <div className="w-full h-full border-2 border-[#EBEBEB] rounded-2xl">
                <p className="p-2 font-bold">Coverage</p>
                <hr />
                <div className="px-4">
                  {viewcourse?.coverage?.map((i, index) => (
                    <p
                      className="text-[#334155] text-xs my-2 flex justify-start items-center gap-x-2"
                      key={index}
                    >
                      <GoDotFill />
                      {i}
                    </p>
                  ))}
                </div>
              </div>
              <div className="w-full h-full border-2 border-[#EBEBEB] rounded-2xl">
                <p className="p-2 font-bold">Audiance</p>
                <hr />
                <div className="px-4">
                  {viewcourse?.audiance?.map((i, index) => (
                    <p
                      className="text-[#334155] text-xs my-2 flex justify-start items-center gap-x-2"
                      key={index}
                    >
                      <GoDotFill />
                      {i},
                    </p>
                  ))}
                </div>
              </div>
              <div className="w-full h-full border-2 border-[#EBEBEB] rounded-2xl">
                <p className="p-2 font-bold">Pre Requistics</p>
                <hr />
                <div className="px-4">
                  {" "}
                  {viewcourse?.preRequisites?.map((i, index) => (
                    <p
                      className="text-[#334155] text-xs my-2 flex justify-start items-center gap-x-2"
                      key={index}
                    >
                      <GoDotFill />
                      {i},
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <p className="text-2xl font-bold mt-4">{viewcourse?.title ?? ""}</p>

            <div
              dangerouslySetInnerHTML={{ __html: viewcourse?.description }}
              className="text-sm text-[#334155] leading-6 mt-3"
            >
              {/* Descriotion: {viewcourse?.description} */}
            </div>
            <p className=" text-xs  flex justify-start items-center gap-x-3 mt-4 ">
              <span className="text-yellow-500">
                {viewcourse?.averageRating ?? 0}
              </span>
              <span>
                <Rate
                  style={{ fontSize: "13px" }}
                  value={viewcourse?.averageRating ?? 0}
                />
              </span>
              | {convertSeconds(viewcourse?.lectureDuration) ?? 0} Total Hours.
              {viewcourse?.lectureCount ?? 0} Lectures. All levels
            </p>
            <p className=" text-xs  flex justify-start items-center gap-x-2 mt-4 ">
              <span className="text-gray1">Audiance:</span>
              <p className="flex justify-start items-center gap-x-2">
                {viewcourse?.audiance?.map((i, index) => (
                  <p key={index}>{i},</p>
                ))}
              </p>
            </p>
            {/* <div className="flex justify-start items-center gap-x-3 mt-4">
              <LazyLoadImage
                src={Images.Logo}
                alt="instructor"
                className="w-6 h-6 rounded-full"
              />
              <p className="text-xs">
                Created by <span className="text-primary">Rohan Kumar</span>
              </p>
            </div> */}

            <hr className="mt-4" />
            <div className="font-inter mt-8">
              <p className="text-lg font-bold">Syllabus</p>
              {viewcourse?.sections?.length > 0 ? (
                <Collapse
                  defaultActiveKey={["1"]}
                  expandIcon={"h"}
                  rootClassName="bg-white"
                  className="mt-4"
                  onChange={() => {}}
                  expandIconPosition={"start"}
                  items={getTopics(viewcourse?.sections)}
                />
              ) : (
                <p className="flex justify-center items-center h-full mt-4 text-lg font-semibold">
                  No syllabus found
                </p>
              )}
            </div>
            <hr className="mt-4" />
          </div>
          <div className="col-span-10 md:col-span-4 w-full">
            <div
              style={{ boxShadow: "0px 0px 8px 0px #3B82F61F" }}
              className="w-full xl:w-3/4 mt-4 md:mt-0 m-auto  p-4 rounded-lg"
            >
              <video preload="metadata" className="rounded-md w-full" controls>
                {" "}
                <source
                  src={`${UPLOAD_ENDPOINT}${viewcourse.trailer}#t=0.1`}
                  type="video/mp4"
                />
              </video>
              <p className="font-bold font-inter mt-4  text-lg">
                Watch Trailer
              </p>
            </div>
            <div
              style={{ boxShadow: "0px 0px 8px 0px #3B82F61F" }}
              className="w-full xl:w-3/4 mt-4  m-auto  p-4 rounded-lg"
            >
              <LazyLoadImage
                src={`${UPLOAD_ENDPOINT}${viewcourse.thumbnail}`}
                onError={(e) => {
                  e.target.src = Images.CourseImage;
                  e.target.alt = "courseImage";
                }}
                alt="product"
                className="rounded-lg w-full"
              />
              <div className="flex justify-between mt-2 items-center ">
                <p className="font-bold font-inter  text-sm">Price</p>
                <p className="font-bold font-inter  text-sm">
                  ₹ {viewcourse?.price ?? 0}
                </p>
              </div>
              <hr className="mt-2" />
              <div className="flex justify-between mt-2 items-center ">
                <p className="font-bold font-inter  text-sm">Discount</p>
                <p className="font-bold font-inter  text-sm">
                  ₹ {viewcourse?.discount ?? 0}
                </p>
              </div>
              {viewcourse?.installments > 1 && (
                <>
                  <hr className="mt-2" />
                  <div className="flex justify-between mt-2 items-center ">
                    <p className="font-bold font-inter  text-sm">Installment</p>
                    <p className="font-bold font-inter  text-sm">
                      {viewcourse?.installments}
                    </p>
                  </div>
                </>
              )}
              {viewcourse?.installments > 1 && installment && (
                <>
                  <hr className="mt-2" />
                  <div className="flex justify-between mt-2 items-center ">
                    <p className="font-bold font-inter  text-lg text-primary">
                      Installmet amount
                    </p>
                    <div className="font-medium font-inter text-sm text-gray1">
                      <p>
                        ₹ {viewcourse?.installments} X{" "}
                        {(
                          ((viewcourse?.price ?? 0) -
                            (viewcourse?.discount ?? 0)) /
                          viewcourse?.installments
                        ).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </>
              )}
              <hr className="mt-2" />
              <div className="flex justify-between mt-2 items-center ">
                <p className="font-bold font-inter  text-lg text-primary">
                  Total
                </p>
                <p className="font-bold font-inter  text-lg text-primary">
                  ₹ {(viewcourse?.price ?? 0) - (viewcourse?.discount ?? 0)}
                </p>
              </div>
              <div>
                {/* <Button
                  onClick={handleAddToCart}
                  type="primary"
                  loading={btnLoading}
                  className="w-full mt-2 bg-black h-10 text-white border-none hover:border-none"
                >
                  Add To Cart
                </Button> */}
                <br />
                {/* <Button
                  onClick={HandleBuyNow}
                  loading={btnLoading2}
                  type="primary"
                  className="w-full bg-white mt-4 h-10 text-black border border-black "
                >
                  Buy Now
                </Button> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseDetail;
