import PublicAuth from "./PublicAuth";
import Login from "../Views/Auth/Login";
import SignUp from "../Views/Auth/SignUp";
import AuthPage from "../Views/Auth/AuthPage";
import { FacebookAuth } from "../Views/Auth/AuthFun";
import ForgetPassword from "../Views/Auth/ForgetPassword";
import ResetPassword from "../Views/Auth/ResetPassword";

export const PublicRoutes = () => {
  return [
    {
      element: <PublicAuth />,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "/auth/code",
          element: <AuthPage />,
        },
        {
          path: "/auth/code",
          element: <FacebookAuth />,
        },
        {
          path: "/signup",
          element: <SignUp />,
        },
        {
          path: "/forgetPassword",
          element: <ForgetPassword />,
        },
        {
          path: "/reset-password",
          element: <ResetPassword />,
        },
      ],
    },
  ];
};
