import { Button } from "antd";
import YTModal from "./YTModal";
import { GrAdd } from "react-icons/gr";

const YtLinks = ({ error, ytLinks, index, addAsset, updateAsset, deleteAsset, formData, setFormData }) =>
  ytLinks ? (
    <div className="grid grid-cols-12 place-items-center content-center gap-x-4 mb-2">
      <p className="col-span-2 text-xs text-right w-full">Youtube Links</p>
      <div className="col-span-10 w-full rounded-none border-none">
        <div className="flex flex-row gap-2 no-scrollbar overflow-x-scroll">
          {ytLinks.length ? ytLinks.map((ytLink, ytLinkIndex) => <YTModal error={error?.[ytLinkIndex]} formData={formData} setFormData={setFormData} ytLink={ytLink} updateAsset={updateAsset} deleteAsset={deleteAsset} index={index} ytLinkIndex={ytLinkIndex} />) : null}
          <Button className="rounded-none h-[50px] aspect-video" onClick={() => addAsset(index, "ytLinks")}>
            <GrAdd className="font-bold text-base text-gray2" />
          </Button>
        </div>
      </div>
    </div>
  ) : null;

export default YtLinks;
