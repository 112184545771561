import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Table,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  addBlogCategory,
  deleteBlogCategory,
  getBlogCategory,
  updateBlogCategory,
} from "./BlogApi";
import { ExtractDate, ExtractTime } from "../../../Utils";

function AddBlogCategory() {
  const [form] = Form.useForm();
  const [blogCategoryData, setBlogCategoryData] = useState([]);
  const [from, setFrom] = useState("add");
  const [filldata, setFilldata] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categorySlug, setCategorySlug] = useState("");

  const generateCategorySlug = (value) => {
    return value
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
  };

  const handleCategoryNameChange = (e) => {
    const inputValue = e.target.value;
    form.setFieldsValue({ categorySlug });

    setCategoryName(inputValue);
    setCategorySlug(generateCategorySlug(inputValue));
  };
  const createBlogCategory = (changedValues) => {
    if (from === "add") {
      const payload = {
        categorySlug: changedValues.categorySlug,
        categoryName: changedValues.categoryName,
      };
      addBlogCategory(payload)
        .then((response) => {
          if (response.success) {
            message.success({ content: response.message });
            form.resetFields();
            FetchBlogCategoryData();
          }
        })
        .catch((error) => console.error(error));
    } else {
      const payload = {
        id: filldata._id,
        categorySlug: changedValues.categorySlug,
        categoryName: changedValues.categoryName,
      };
      updateBlogCategory(payload)
        .then((response) => {
          if (response.success) {
            message.success({ content: response.message });
            form.resetFields();
            setFrom("add");
            FetchBlogCategoryData();
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const FetchBlogCategoryData = () => {
    setIsLoading(true);
    getBlogCategory()
      .then((response) => {
        if (response.status) {
          setIsLoading(false);
          setBlogCategoryData(response.data);
        }
      })
      .catch((err) => {
        console.error("Error fetching data", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    FetchBlogCategoryData();
  }, []);

  useEffect(() => {
    if (from === "edit" && filldata) {
      form.setFieldsValue({
        categoryName: filldata?.categoryName,
        categorySlug: filldata?.categorySlug,
      });
    } else {
      form.resetFields();
    }
  }, [from, filldata, form]);

  const DeleteAction = (record) => {
    deleteBlogCategory(record._id)
      .then((response) => {
        if (response.status) {
          message.success(response.message);
          FetchBlogCategoryData();
        }
      })
      .catch((error) => console.error(error));
  };

  const columns = [
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "categoryName",
      render: (data) => <p>{data}</p>,
    },
    {
      title: "Category Slug",
      dataIndex: "categorySlug",
      key: "categorySlug",
      render: (data) => <p>{data}</p>,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => (
        <p>
          {ExtractDate(data)} • {ExtractTime(data)}
        </p>
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (data, record) => (
        <Button
          size="small"
          onClick={() => {
            setFrom("edit");
            setFilldata(record);
          }}
        >
          <EditOutlined />
        </Button>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (data, record) => (
        <Popconfirm
          title="Are you sure you want to delete this Blog Category?"
          onConfirm={() => DeleteAction(record)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2  items-center m-2">
        <div className="ml-10">
          <p className="text-primary font-dmsans leading-7">Hello!</p>
          <p className="text-lg font-bold font-dmsans ">
            Create a New Blog Category
          </p>
        </div>
      </div>
      <div className="ml-10 md:ml-20 mt-8">
        <Divider orientation="left">Add Blog Category</Divider>
        <Form
          layout="vertical"
          form={form}
          onFinish={createBlogCategory}
          initialValues={{ categoryName: "", categorySlug: "" }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Category Name"
                name="categoryName"
                rules={[
                  { required: true, message: "Please enter a Name" },
                  { type: "string", message: "Please enter a valid Name" },
                ]}
              >
                <Input
                  placeholder="Enter Category Name"
                  value={categoryName}
                  onChange={handleCategoryNameChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Category Slug"
                name="categorySlug"
                rules={[
                  { required: true, message: "Please enter Category Slug" },
                  {
                    min: 6,
                    message: "Slug must be at least 6 characters",
                  },
                  {
                    pattern: /^[a-zA-Z0-9-]+$/,
                    message:
                      "Slug can only contain letters, numbers, and hyphens",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Category Slug"
                  value={categorySlug}
                  onChange={(e) => setCategorySlug(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Button
              className="rounded-none p-[0px_32px_0px_32px] hover:bg-black text-white font-dmsans font-semibold  bg-orange text-xs "
              size="small"
              htmlType="submit"
            >
              {from === "add" ? "Create Blog Category" : "Update Blog Category"}
            </Button>
          </Row>
        </Form>
        <Divider orientation="left">Blog Category List</Divider>
        <Table
          columns={columns}
          dataSource={blogCategoryData}
          pagination={false}
          loading={isLoading}
        />
      </div>
    </div>
  );
}

export default AddBlogCategory;
