import { Delete, Get, getAPIUrl, Post } from "../../../ApiServices/apiMethod";
import { HomePageService } from "../../../ApiServices/Axios/Index";

export const BlogEndpoint = {
  getBlog: `/lms/getBlogsAdmin?`,
  addBlog: `/lms/createBlog`,
  deleteBlog: `/lms/deleteBlog?eventId=`,
  editBlog: `/lms/updateBlog?getSlug=`,
  modifyHighlightedStatus: ``,
  addBlogCategory: `/lms/createBlogCategory`,
  getBlogCategory: `/lms/fetchBlogCategory`,
  updateBlogCategory: `/lms/updateBlogCategory`,
  deleteBlogCategory:`/lms/deleteCategoryBlog?blogCategoryId=`,
};

export const getBlog = (param = "") => {
  const url = getAPIUrl(BlogEndpoint.getBlog, param);
  return Get(HomePageService, url, true);
};
export const getBlogCategory = (param = "") => {
  const url = getAPIUrl(BlogEndpoint.getBlogCategory, param);
  return Get(HomePageService, url, true);
};
export const addBlog = (data, param = "") => {
  const url = getAPIUrl(BlogEndpoint.addBlog, param);
  return Post(HomePageService, url, data, true);
};
export const addBlogCategory = (data, param = "") => {
  const url = getAPIUrl(BlogEndpoint.addBlogCategory, param);
  return Post(HomePageService, url, data, true);
};
export const updateBlogCategory = (data, param = "") => {
  const url = getAPIUrl(BlogEndpoint.updateBlogCategory, param);
  return Post(HomePageService, url, data, true);
};
export const editBlog = (data, param = "") => {
  const url = getAPIUrl(BlogEndpoint.editBlog, param);
  return Post(HomePageService, url, data, true);
};
export const deleteBlog = (param = "") => {
  const url = getAPIUrl(BlogEndpoint.deleteBlog, param);
  return Delete(HomePageService, url, param);
};
export const deleteBlogCategory = (param = "") => {
  const url = getAPIUrl(BlogEndpoint.deleteBlogCategory, param);
  return Delete(HomePageService, url, param);
};
export const modifyHighlightedStatus = (data, param = "") => {
  const url = getAPIUrl(BlogEndpoint.modifyHighlightedStatus, param);
  return Post(HomePageService, url, data, true);
};
