import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courseCreated: false,
};

export const b2bSlice = createSlice({
  name: "E2C",
  initialState,
  reducers: {
    setHeadings: (state, action) => {
      state.heading = action.payload;
    },
    setCourseCreated: (state, action) => {
      state.courseCreated = action.payload.courseCreated;
    },
  },
});

export const { setHeadings, setCourseCreated } = b2bSlice.actions;

export default b2bSlice.reducer;
