import React, { lazy } from "react";
import { useSelector } from "react-redux";
import Courses from "../Course/Courses";
import CourseDetail from "./CourseDetail";

const ProtectedCourseDetail = () => {
  const courseCreated = useSelector((state) => state.E2C.courseCreated);

  return courseCreated ? <CourseDetail /> : <Courses />;
};

export default ProtectedCourseDetail;
