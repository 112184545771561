import { Get, getAPIUrl, Post } from "../../../ApiServices/apiMethod";
import { HomePageService } from "../../../ApiServices/Axios/Index";

export const HomePageEndpoint = {
  gethomepage: `/lms/getHomePageData`,
  addHomePage: `/lms/createHomePageData?homePageId=`,
 
};

export const gethomepage = (param = "") => {
  const url = getAPIUrl(HomePageEndpoint.gethomepage, param);
  return Get(HomePageService, url, true);
};
export const addHomePage = (data, param = "") => {
  const url = getAPIUrl(HomePageEndpoint.addHomePage, param);
  return Post(HomePageService, url, data, true);
};

