import { Delete, Get, getAPIUrl, Post } from "../../../ApiServices/apiMethod";
import { HomePageService } from "../../../ApiServices/Axios/Index";

export const EventEndpoint = {
  getevent: `/lms/getAllEvents?`,
  addEvent: `/lms/createEvent`,
  updateEvent: `/lms/updateEvent?eventId=`,
  deleteEvent: `/lms/deleteEvent?eventId=`,
};

export const getevent = (param = "") => {
  const url = getAPIUrl(EventEndpoint.getevent, param);
  return Get(HomePageService, url, true);
};
export const addEvent = (data, param = "") => {
  const url = getAPIUrl(EventEndpoint.addEvent, param);
  return Post(HomePageService, url, data, true);
};
export const updateEvent = (data, param = "") => {
  const url = getAPIUrl(EventEndpoint.updateEvent, param);
  return Post(HomePageService, url, data, true);
};
export const deleteEvent = (param = "") => {
  const url = getAPIUrl(EventEndpoint.deleteEvent, param);
  return Delete(HomePageService,url, param);
};
