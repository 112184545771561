import { Button } from "antd";
import DocModal from "./DocModal";
import { GrAdd } from "react-icons/gr";

const Docs = ({ error, docs, index, addAsset, updateAsset, deleteAsset, formData, setFormData }) =>
  docs ? (
    <div className="grid grid-cols-12 place-items-center content-center gap-x-4 mb-2">
      <p className="col-span-2 text-xs text-right w-full">Documents</p>
      <div className="col-span-10 w-full rounded-none border-none">
        <div className="flex flex-row gap-2 no-scrollbar overflow-x-scroll">
          {docs.length ? docs.map((doc, docIndex) => <DocModal error={error[docIndex]} formData={formData} setFormData={setFormData} doc={doc} updateAsset={updateAsset} deleteAsset={deleteAsset} index={index} docIndex={docIndex} />) : null}
          <Button className="rounded-none h-[50px] aspect-video" onClick={() => addAsset(index, "docs")}>
            <GrAdd className="font-bold text-base text-gray2" />
          </Button>
        </div>
      </div>
    </div>
  ) : null;

export default Docs;
