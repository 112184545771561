import { Button, Col, Divider, Form, Image, Input, message, Row } from "antd";
import React, { useRef, useState } from "react";
import { addCarousal } from "./CarousalApiFunction";
import {  PlusOutlined } from "@ant-design/icons";
import { presignedApi } from "../Course/CourseFun";
import axios from "axios";
import { UPLOAD_ENDPOINT } from "../../../ApiServices/Axios/Index";
import IconButton from "../../../CommonComponents/IconButton";
import { MdDeleteOutline } from "react-icons/md";

function AddCarousal({apiCall, setFill, from }) {
  const [carousalImages, setCarousalImages] = useState([]); 
  const [uploadProgress, setUploadProgress] = useState({});
  const [form] = Form.useForm();
  const formRef = useRef(form);

  const handleUploadCarousal = async (event) => {
    try {
      const selectedFile = event.target.files[0];
      if (!selectedFile) {
        console.error("No file selected.");
        return;
      }

      const fileExtension = selectedFile.type.split("/").pop();
      const { preSignedUploadUrl, Key } = await presignedApi(
        `?mimetype=${fileExtension}`
      );

      if (preSignedUploadUrl) {
        await axios.put(preSignedUploadUrl, selectedFile, {
          headers: {
            "Content-Type": selectedFile.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress((prev) => ({
              ...prev,
              [Key]: percentCompleted,
            }));
          },
        });
        setCarousalImages((prev) => [...prev, Key]);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const createCarousal = () => {
    const payload = {
      carousel: carousalImages,
    };

    addCarousal(payload)
      .then((response) => {
        if (response.status) {
          message.success({ content: response.message });
          apiCall();
          form.resetFields();
          setCarousalImages([]);
          setFill(null);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRemoveImage = (key) => {
    setCarousalImages((prev) => prev.filter((image) => image !== key));
  };

  return (
    <div>
      <Divider orientation="left" className="text-lg font-dmsans font-semibold">
        {from === "add" ? "Add" : "Edit"} Carousel{" "}
      </Divider>
      <Form
        className="bg-white p-6"
        layout="vertical"
        onFinishFailed={(err) => console.error(err)}
        onFinish={createCarousal}
        form={form}
        ref={formRef}
      >
        <Row gutter={[16, 16]}>
          {carousalImages.map((imageKey, index) => (
            <Col key={index} span={6}>
              <div className="relative">
                <Image
                  src={`${UPLOAD_ENDPOINT}${imageKey}`}
                  alt={`Carousel Image ${index + 1}`}
                  height={100}
                  width={150}
                  style={{
                    objectFit: "cover",
                  }}
                />
                <div className="h-[100px] aspect-video flex flex-row justify-center items-center absolute top-0 left-0">
                  <IconButton
                    Icon={MdDeleteOutline}
                    onClick={() => handleRemoveImage(imageKey)}
                  />
                </div>
              </div>
            </Col>
          ))}
          <Col span={8}>
            <label htmlFor="upload-img">
              <div className="border h-[100px] flex justify-center items-center cursor-pointer">
                <PlusOutlined />
                <p>Add Image</p>
              </div>
            </label>
            <Input
              type="file"
              id="upload-img"
              style={{
                display: "none",
              }}
              onChange={(e) => handleUploadCarousal(e)}
              accept="image/*"
            />
          </Col>
        </Row>
        <Row className="flex justify-between">
          <Form.Item>
            <Button
              className="rounded-none p-[0px_32px_0px_32px]   text-sm font-dmsans font-semibold text-gray2 "
              size="small"
              onClick={() => form.resetFields()}
            >
              Cancel
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              className="rounded-none p-[0px_32px_0px_32px] hover:bg-black text-white font-dmsans font-semibold  bg-orange text-xs "
              size="small"
              htmlType="submit"
            >
              Create Carousal
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}

export default AddCarousal;
