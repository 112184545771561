import { Button } from "antd";
import LectureModal from "./LectureModal";
import { GrAdd, GrFormCheckmark } from "react-icons/gr";

const Lectures = ({ error, lectures, index, addAsset, updateAsset, deleteAsset, formData, setFormData }) =>
  lectures ? (
    <div className="grid grid-cols-12 place-items-center content-center gap-x-4 mb-2">
      <p className="col-span-2 text-xs text-right w-full">Lectures</p>
      <div className="col-span-10 w-full rounded-none border-none">
        <div className="flex flex-row gap-2 no-scrollbar overflow-x-scroll">
          {lectures.length ? lectures.map((lecture, lectureIndex) => <LectureModal error={error?.[lectureIndex]} formData={formData} setFormData={setFormData} lecture={lecture} updateAsset={updateAsset} deleteAsset={deleteAsset} index={index} lectureIndex={lectureIndex} />) : null}
          <Button className="rounded-none h-[50px] aspect-video" onClick={() => addAsset(index, "lectures")}>
            <GrAdd className="font-bold text-base text-gray2" />
          </Button>
        </div>
      </div>
    </div>
  ) : null;

export default Lectures;
