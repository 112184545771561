import { Button, Divider, Image, message, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { addCarousal, getCarousal } from "./CarousalApiFunction";
import { ExtractDate, ExtractTime } from "../../../Utils";
import "../Coupon/coupon.css";
import AddCarousal from "./AddCarousal";
import { UPLOAD_ENDPOINT } from "../../../ApiServices/Axios/Index";
import { MdDeleteOutline } from "react-icons/md";
import IconButton from "../../../CommonComponents/IconButton";

function CoursesCorousal() {
  const [nextCursor, setnextCursor] = useState("");
  const [loadingMore, setLoadingMore] = useState(false);
  const [carousalData, setCarousalData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [from, setFrom] = useState("add");
  const [filldata, setFilldata] = useState({});

  function FetchCarousalList(cursor = "") {
    if (cursor) setLoadingMore(true);
    else setIsLoading(true);
    getCarousal()
      .then((response) => {
        if (response.status) {
          setnextCursor(response.data.nextCursor);

          const newCarousalImage = response.data || [];
          {
            cursor === ""
              ? setCarousalData(response.data)
              : setCarousalData((prevData) => [
                  ...prevData,
                  ...newCarousalImage,
                ]);
          }
        }
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setIsLoading(false);
        setLoadingMore(false);
      });
  }

  useEffect(() => {
    FetchCarousalList();
  }, []);

  const handleRemoveImage = (recordKey, imageIndex) => {
    const updatedData = carousalData.map((item) => {
      if (item.key === recordKey) {
        const updatedCarousel = [...item.carousel];
        updatedCarousel.splice(imageIndex, 1);
        return { ...item, carousel: updatedCarousel };
      }
      return item;
    });

    setCarousalData(updatedData);

    const updatedRecord = updatedData.find((item) => item.key === recordKey);

    addCarousal(updatedRecord)
      .then((response) => {
        if (response.status) {
          message.success({ content: response.message });
          FetchCarousalList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    {
      title: "Image data",
      dataIndex: "carousel",
      key: "carousel",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <div className="text-xs font-dmsans flex flex-wrap gap-2">
          {record.carousel && record.carousel.length > 0
            ? record.carousel.map((i, id) => (
                <div
                  key={id}
                  className="relative w-[70px] h-[50px] flex justify-center items-center"
                >
                  <Image
                    height={50}
                    width={70}
                    src={`${UPLOAD_ENDPOINT}${i}`}
                    alt={`Image ${id}`}
                    style={{ objectFit: "cover" }}
                  />
                  <div className="absolute top-0 right-0">
                    <IconButton
                      Icon={MdDeleteOutline}
                      onClick={() => handleRemoveImage(record.key, id)}
                    />
                  </div>
                </div>
              ))
            : "No images available"}
        </div>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">
          {ExtractDate(record.createdAt)}•{ExtractTime(record.createdAt)}
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2  items-center m-2">
        <div className="ml-10">
          <p className="text-primary font-dmsans leading-7">Hello!</p>
          <p className="text-lg font-bold font-dmsans ">
            Add a Course Corousal Image
          </p>
        </div>
      </div>
      <div className="ml-10 md:ml-20 mt-8">
        <AddCarousal
          Fill={filldata}
          setFill={setFilldata}
          from={from}
          setFrom={setFrom}
          apiCall={FetchCarousalList}
        />
        <Divider
          orientation="left"
          className="text-lg font-dmsans font-semibold"
        >
          Carousal Image List
        </Divider>
        <div
          style={{ maxHeight: "300px", overflow: "auto" }}
          className="bg-white"
        >
          <Table
            columns={columns}
            dataSource={carousalData}
            className="w-screen sm:w-screen"
            loading={isLoading}
            pagination={false}
          />
        </div>
      </div>
    </>
  );
}
export default CoursesCorousal;
