import React, { Suspense, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../ImageController/Index";
import { Button, Checkbox, Divider, Form, Image, Input, Spin } from "antd";
import {
  dispatchtoast,
  emailPattern,
  makeLabel,
  setLocalStorageItem,
} from "../../Utils";
import CommonInput from "../../CommonComponents/CommonInput";
import { useNavigate } from "react-router-dom";
import { ForgotPassoword, resetPassword } from "./AuthFun";

const ResetPassword = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const url = window.location.href;

  const params = new URLSearchParams(new URL(url).search);

  const token = params.get("token");

  const handleResetPassword = (val) => {
    let payload = {
      password: val.password,
      confirmPassword: val.confirmPassword,
      token: token,
    };
    setBtnLoading(true);
    resetPassword(payload)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          // setLocalStorageItem("accessToken", res.token);
          // setLocalStorageItem("IsLoggesIn", true);
          navigate("/");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };

  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-screen">
          <Spin spinning={true}></Spin>
        </div>
      }
    >
      <div className="container items-center h-screen">
        <div className="grid grid-cols-2 place-items-center ">
          <div className="w-full bg-authBg h-screen hidden md:flex justify-center items-center ">
            <LazyLoadImage
              src={Images.authbanner}
              alt="signupimage"
              className="w-[100%] h-screen object-cover object-right"
            />
          </div>
          <div className="w-full h-screen flex justify-center items-center col-span-2 md:col-span-1">
            <div className="w-3/4 m-auto">
              <div className="font-poppins text-start text-2xl font-normal mb-8">
                <p>Reset Password</p>
              </div>
              <Form onFinish={(val) => handleResetPassword(val)}>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Password is required",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  label={makeLabel("New Password")}
                >
                  <Input.Password
                    className="rounded-md hover:border p-3 hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
                    placeholder="Enter New Password"
                  />
                </Form.Item>

                <Form.Item
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Confirm Password is required",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Passwords do not match!")
                        );
                      },
                    }),
                  ]}
                  labelCol={{ span: 24 }}
                  label={makeLabel("Confirm New Password")}
                >
                  <Input.Password
                    className="rounded-md hover:border p-3 hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
                    placeholder="Re-Enter New Password"
                  />
                </Form.Item>

                <div className="flex items-center mt-4">
                  <Button
                    loading={btnLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ color: "white" }}
                    className="font-poppins p-5 text-white rounded-md bg-primary w-full"
                  >
                    Reset Password
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default ResetPassword;
