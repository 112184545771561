import { Divider, Table } from "antd";
import React from "react";
import "../Coupon/coupon.css";
import { useLocation } from "react-router-dom";

function ShowParticipants() {
  const location = useLocation();
  const fill = location?.state?.fill || [];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.name}</p>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.email}</p>
      ),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.contact}</p>
      ),
    },
    {
      title: "location",
      dataIndex: "location",
      key: "location",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.location}</p>
      ),
    },
  ];

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2  items-center m-2">
        <div className="ml-10">
          <p className="text-primary font-dmsans leading-7">Hello!</p>
          <p className="text-lg font-bold font-dmsans ">Event Participants</p>
        </div>
      </div>

      <div className="ml-10 md:ml-20 mt-8">
        <Divider
          orientation="left"
          className="text-lg font-dmsans font-semibold"
        >
          Event Participants Details
        </Divider>
        <div
          style={{ maxHeight: "500px", overflow: "auto" }}
          className="bg-white"
        >
          <Table
            size="small"
            columns={columns}
            dataSource={fill ?? []}
            className="w-screen sm:w-screen"
            pagination={false}
          />
        </div>
      </div>
    </>
  );
}
export default ShowParticipants;
