import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, message, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { UPLOAD_ENDPOINT } from "../../../../../ApiServices/Axios/Index";
import { presignedApi } from "../../CourseFun";
import axios from "axios";
import { GrEdit } from "react-icons/gr";
import IconButton from "../../../../../CommonComponents/IconButton";
import { MdDeleteOutline } from "react-icons/md";
import { BsUpload } from "react-icons/bs";

const DocModal = ({ error, doc, updateAsset, deleteAsset, index, docIndex, formData, setFormData }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef(form);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [keyDoc, setKeyDoc] = useState(doc.link ?? "");

  const onOpen = () => {
    form.setFieldsValue({
      title: doc.title || "",
      link: doc.link || "",
      previewLink: doc.previewLink || "",
    });
    setOpen(true);
  };
  const onCancel = () => {
    setOpen(false);
  };

  const handleValuesChange = (changedValues) => {
    const data = {
      ...changedValues,
      link: keyDoc,
    };
    if (!data.link || !data.title) {
      message.error("Invalid Link", 2);
    } else {
      updateAsset(index, "docs", docIndex, data);
      onCancel();
    }
  };
  useEffect(() => {
    if (!doc.link || !doc.title) {
      onOpen();
    }
  }, [doc]);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    try {
      if (!selectedFile) {
        console.error("No file selected.");
        return;
      }

      const { preSignedUploadUrl, Key } = await getPresignedUrl(selectedFile);
      if (preSignedUploadUrl) {
        await uploadToPresignedUrl(preSignedUploadUrl, selectedFile);
        setKeyDoc(Key);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const getFileExtension = (mimeType) => {
    const extension = mimeType.split("/").pop();
    return extension;
  };

  const getPresignedUrl = async (selectedFile) => {
    try {
      const fileExtension = getFileExtension(selectedFile.type);
      const res = await presignedApi(`?mimetype=${fileExtension}`);

      if (res.success) {
        return { preSignedUploadUrl: res.preSignedUploadUrl, Key: res.Key };
      }
    } catch (err) {
      console.error(err);
    }
    return { preSignedUploadUrl: null, Key: null };
  };

  const uploadToPresignedUrl = async (presignedUrl, selectedFile) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      await axios.put(presignedUrl, selectedFile, {
        headers: {
          "Content-Type": selectedFile.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <>
      <Modal footer={null} open={open} onCancel={onCancel} title="Add Doc Details">
        <Form
          className="bg-white p-6"
          layout="vertical"
          onFinishFailed={(err) => console.log(err)}
          onFinish={(changedValues) => {
            handleValuesChange(changedValues);
          }}
          form={form}
          ref={formRef}
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: "Please enter title",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="link" label="Link">
            {keyDoc ? (
              <div className="h-[100px] aspect-video d-flex justify-center items-center relative">
                <video src={`${UPLOAD_ENDPOINT}${keyDoc}`} autoPlay={false} className=" h-[100px] aspect-video">
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              // <div className="h-[100px] aspect-video flex justify-center items-center border">
              //   <CiCircleCheck size={50} />
              // </div>
              <>
                <label for="upload-photo" className="rounded-none border flex justify-center items-center h-[100px] aspect-video">
                  <div className="rounded-none border flex justify-center items-center h-[100px] aspect-video">
                    {uploadProgress > 0 && uploadProgress < 100 ? (
                      <div className="animate-pulse h-[100px] aspect-video">
                        <div className={`bg-slate-300 h-[100px] flex flex-row justify-center items-center`} style={{ width: `${uploadProgress}%` }}>
                          <p>{`${uploadProgress}%`}</p>
                        </div>
                      </div>
                    ) : uploadProgress === 0 ? (
                      <div className="flex flex-col justify-center items-center h-[100px] aspect-video gap-2">
                        {/* upload icon */}
                        <BsUpload size={20} />
                        <p>Click to Upload</p>
                      </div>
                    ) : null}
                  </div>
                </label>
                <Input type="file" id="upload-photo" style={{ opacity: "0", position: "absolute", zIndex: "-1" }} onChange={handleFileChange} />
              </>
            )}
          </Form.Item>
          <div>
            <Form.Item className="flex justify-center mt-4">
              <Button className="rounded-none p-[0px_32px_0px_32px] hover:bg-gray-100  font-dmsans font-semibold bg-orange text-xs" size="large" htmlType="onSubmit" disabled={!keyDoc}>
                Add
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <div className="h-[50px] aspect-video d-flex justify-center items-center relative">
        {doc && doc.link && doc.link.length ? (
          <>
            <video src={`${UPLOAD_ENDPOINT}${doc.link}`} autoPlay={false} className=" h-[50px] aspect-video">
              Your browser does not support the video tag.
            </video>
            <div className="absolute  top-5 right-3 flex flex-row gap-6 z-10">
              <GrEdit title="Edit Lecture" className="font-extrabold text-base text-[#FF6636] cursor-pointer" onClick={onOpen} />
              <DeleteOutlined title="Delete Lecture" className="font-bold text-base text-[#FF6636] cursor-pointer" onClick={() => deleteAsset(index, "docs", docIndex)} />
            </div>
            {error && (error?.link || error?.previewLink || error.duration || error.title) ? <small>{"Missing Params"}</small> : null}
          </>
        ) : (
          <div className="animate-pulse">
            <div className="h-[50px] flex flex-row justify-center items-center gap-2">
              <IconButton Icon={GrEdit} onClick={onOpen} />
              <IconButton Icon={MdDeleteOutline} onClick={() => deleteAsset(index, "docs", docIndex)} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default DocModal;
