import { Get, getAPIUrl, Post } from "../../../ApiServices/apiMethod";
import { Coursendpoint } from "./CourseEndpoint";
import { CourseService } from "../../../ApiServices/Axios/Index";

export const addCourse = (data, param = "") => {
  const url = getAPIUrl(Coursendpoint.addCourse, param);
  return Post(CourseService, url, data, true);
};
export const presignedApi = (param = "") => {
  const url = getAPIUrl(Coursendpoint.presignedApi, param);
  return Get(CourseService, url, true);
};
export const getYtDurationApi = (param = "") => {
  const url = getAPIUrl(Coursendpoint.getYtDuration, param);
  return Get(CourseService, url, true);
};
