import { CKEditor, useCKEditorCloud } from "@ckeditor/ckeditor5-react";
import { getBase64 } from ".";

function MyEditor({ data, onChange, onPaste,placeholder }) {
  const cloud = useCKEditorCloud({
    version: "44.1.0",
    premium: true,
  });

  if (cloud.status === "loading") {
    return <div>Loading...</div>;
  }

  if (cloud.status === "error") {
    console.error(cloud.error);
    return <div>Error!</div>;
  }

  const {
    ClassicEditor,
    Bold,
    Essentials,
    Italic,
    Image,
    Heading,
    ImageUpload,
    Paragraph,
    Undo,
    Font,
    Link,
    MediaEmbed,
    PageBreak,
    Table,
    BlockQuote,
    Underline,
    Subscript,
    Superscript,
  } = cloud.CKEditor;

  return (
    <CKEditor
      editor={ClassicEditor}
      data={data}
      config={{
        toolbar: {
          items: [
            "undo",
            "redo",
            "|",
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "subscript",
            "superscript",
            "|",
            "fontColor",
            "fontSize",
            "fontFamily",
            "fontBackgroundColor",
            "|",
            "link",
            "imageUpload",
            "mediaEmbed",
            "|",
            "insertTable",
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "|",
            "pageBreak",
            "blockQuote",
          ],
        },
        plugins: [
          Essentials,
          Bold,
          Italic,
          Image,
          ImageUpload,
          Paragraph,
          Undo,
          Font,
          MediaEmbed,
          Heading,
          Link,
          Table,
          PageBreak,
          BlockQuote,
          Underline,
          Subscript,
          Superscript,
        ],
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties",
          ],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        fontColor: {
          colors: [
            { color: "hsl(0, 0%, 0%)", label: "Black" },
            { color: "hsl(0, 0%, 30%)", label: "Dim grey" },
            { color: "hsl(0, 0%, 60%)", label: "Grey" },
            { color: "hsl(0, 0%, 90%)", label: "Light grey" },
            { color: "hsl(0, 0%, 100%)", label: "White", hasBorder: true },
            { color: "hsl(0, 75%, 60%)", label: "Red" },
            { color: "hsl(30, 75%, 60%)", label: "Orange" },
            { color: "hsl(60, 75%, 60%)", label: "Yellow" },
            { color: "hsl(120, 75%, 60%)", label: "Green" },
            { color: "hsl(240, 75%, 60%)", label: "Blue" },
          ],
          columns: 5,
        },
        fontSize: {
          options: [
            "tiny",
            "small",
            "default",
            "big",
            "huge",
            "12px",
            "14px",
            "16px",
            "18px",
            "20px",
          ],
        },
        fontFamily: {
          options: [
            "default",
            "Arial",
            "Courier New",
            "Georgia",
            "Times New Roman",
            "Verdana",
            "Tahoma",
            "Trebuchet MS",
            "Comic Sans MS",
          ],
        },
        fontBackgroundColor: {
          colors: [
            { color: "#FFFF00", label: "Yellow" },
            { color: "#FF0000", label: "Red" },
            { color: "#00FF00", label: "Green" },
            { color: "#0000FF", label: "Blue" },
            { color: "#FF00FF", label: "Magenta" },
            { color: "#00FFFF", label: "Cyan" },
            { color: "#F0F0F0", label: "Light Gray" },
          ],
        },
        image: {
          toolbar: [
            "imageTextAlternative",
            "|",
            "imageStyle:full",
            "imageStyle:side",
          ],
        },
        imageUpload: {
          uploadUrl: "/your/upload-endpoint",
        },
        extraPlugins: [uploadPlugin],
        mediaEmbed: {
          previewsInData: true,
        },
        licenseKey:
          "eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NjY3OTM1OTksImp0aSI6IjhlNDEyNzNiLTZjMTQtNDY4ZC04N2U2LWRmMjQ3ZjFhZjVkYiIsImxpY2Vuc2VkSG9zdHMiOlsiMTI3LjAuMC4xIiwibG9jYWxob3N0IiwiMTkyLjE2OC4qLioiLCIxMC4qLiouKiIsIjE3Mi4qLiouKiIsIioudGVzdCIsIioubG9jYWxob3N0IiwiKi5sb2NhbCJdLCJ1c2FnZUVuZHBvaW50IjoiaHR0cHM6Ly9wcm94eS1ldmVudC5ja2VkaXRvci5jb20iLCJkaXN0cmlidXRpb25DaGFubmVsIjpbImNsb3VkIiwiZHJ1cGFsIl0sImxpY2Vuc2VUeXBlIjoiZGV2ZWxvcG1lbnQiLCJmZWF0dXJlcyI6WyJEUlVQIl0sInZjIjoiNTgzZDc2ZmEifQ.5Kiv85ex2CPxMUOFRyroZqIYaMLVPsLff8n7RSdTZsqKnaIsHsryubLcM6rSDUDdh1xRnb_LU8lekg4EoK4-Zw",
      }}
      placeholder={placeholder}
      onChange={onChange}
      onPaste={onPaste}
    />
  );
}

const uploadAdapter = (loader) => {
  return {
    upload: async () => {
      const file = await loader.file;
      const base64 = await getBase64(file);
      return {
        default: base64,
      };
    },
  };
};

function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

export default MyEditor;
