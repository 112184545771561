import React, { useEffect, useState } from "react";
import { message, Tabs } from "antd";
import "../Course/course.css";
import Basic from "./Basic";
import Advanced from "./Advanced";
import Publish from "./Publish";
import { Images } from "../../../ImageController/Index";
import Curriculum from "./Curriculum";
import { addCourse } from "./CourseFun";
import { dispatchtoast } from "../../../Utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCourseCreated } from "../../../Store/Slices";
const { TabPane } = Tabs;

const Courses = () => {
  const [activeTab, setActiveTab] = useState("1");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const courseCreated = useSelector((state) => state.E2C.courseCreated);

  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    category: "",
    description: "",
    topic: "",
    language: "",
    subtitleLanguage: "",
    level: "",
    thumbnail: "",
    trailer: "",
    coverage: [""],
    audiance: [""],
    preRequisites: [""],
    price: 0,
    discount: 0,
    allowDownload: false,
    trialEnabled: false,
    installments: 1,
    welcomeMessage: "",
    congratulationMessage: "",
    sections: [],
  });

  const [formCompletion, setFormCompletion] = useState({
    basicInfo: false,
    advancedInfo: false,
    curriculum: false,
  });

  const onChange = (key) => {
    setActiveTab(key);
  };

  const addCourseDetails = (data) => {
    addCourse(data)
      .then((res) => {
        if (res.success) {
          dispatchtoast(res.message);
          dispatch(setCourseCreated({ courseCreated: true }));
          setActiveTab("1");
          navigate("/courseDetails", {
            state: {
              course: res?.course,
            },
          });
        } else {
          const errors = res.error.details.map((error) => dispatchtoast(error.message, "error"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    dispatch(setCourseCreated({ courseCreated: false }));
  }, [dispatch, courseCreated]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "1":
        return <Basic setFormData={setFormData} formData={formData} setActiveTab={setActiveTab} />;
      case "2":
        return <Advanced setFormData={setFormData} formData={formData} setActiveTab={setActiveTab} />;
      case "3":
        return <Curriculum setFormData={setFormData} formData={formData} setActiveTab={setActiveTab} />;
      case "4":
        return <Publish setFormData={setFormData} formData={formData} onSubmit={addCourseDetails} setActiveTab={setActiveTab} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2  items-center m-2">
        <div className="ml-10">
          <p className="text-primary font-dmsans leading-7">Hello!</p>
          <p className="text-lg font-bold font-dmsans ">Create a New Course</p>
        </div>
      </div>
      <div className="ml-10 md:ml-20">
        <Tabs activeKey={activeTab} onChange={onChange}>
          <TabPane
            tab={
              <span className={`custom-tab-title ${activeTab === "1" ? "active-tab" : "inactive-tab"}`}>
                <img src={Images.Stack} alt="Icon" className="icon" />
                Basic Information
              </span>
            }
            key="1"
            disabled={false}
          />
          <TabPane
            tab={
              <span className={`custom-tab-title ${activeTab === "2" ? "active-tab" : "inactive-tab"}`}>
                <img src={Images.ClipBoardText} alt="Icon" className="icon" />
                Advanced Information
              </span>
            }
            key="2"
            disabled={!formCompletion.basicInfo}
          />
          <TabPane
            tab={
              <span className={`custom-tab-title ${activeTab === "3" ? "active-tab" : "inactive-tab"}`}>
                <img src={Images.MonitorPlay} alt="Icon" className="icon" />
                Curriculum
              </span>
            }
            key="3"
            disabled={!formCompletion.advancedInfo}
          />
          <TabPane
            tab={
              <span className={`custom-tab-title ${activeTab === "4" ? "active-tab" : "inactive-tab"}`}>
                <img src={Images.miniPlayCircle} alt="Icon" className="icon" />
                Publish Course
              </span>
            }
            key="4"
            disabled={!formCompletion.curriculum}
          />
        </Tabs>
        <div style={{ marginTop: 16 }}>{renderTabContent()}</div>
      </div>
    </>
  );
};

export default Courses;
