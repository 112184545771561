import React, { Suspense, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../ImageController/Index";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Image,
  Input,
  Radio,
  Spin,
} from "antd";
import { emailPattern, makeLabel, setLocalStorageItem } from "../../Utils";
import CommonInput from "../../CommonComponents/CommonInput";
import { FaArrowRight, FaGoogle } from "react-icons/fa";
import { FacebookAuth, GoogleAuth, userLogin } from "./AuthFun";
import { NavLink, useNavigate } from "react-router-dom";

const Login = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const handleLogin = (val) => {
    setBtnLoading(true);
    userLogin(val)
      .then((res) => {
        if (res.status) {
          setLocalStorageItem("accessToken", res.token);
          setLocalStorageItem("IsLoggesIn", true);
          navigate("/dashboard");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  const handleGoogleAuth = () => {
    GoogleAuth()
      .then((res) => {
        window.open(res.url, "_self");
      })
      .catch((err) => console.log(err));
  };
  const handleFacebookAuth = () => {
    FacebookAuth()
      .then((res) => {
        window.open(res.url, "_self");
      })
      .catch((err) => console.log(err));
  };

  const GoogleLogo = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="24"
      height="24"
      style={{ display: "inline-block", verticalAlign: "middle" }}
    >
      <path
        fill="#e53935"
        d="M24 9.5c3.2 0 6.1 1.2 8.3 3.2l6.2-6.2C34.4 3.6 29.4 1.5 24 1.5 14.8 1.5 7 7.3 3.8 15l7.3 5.7C12.6 14.2 17.8 9.5 24 9.5z"
      />
      <path
        fill="#4caf50"
        d="M44.6 20.5H24v8.9h11.8c-2.2 5.8-7.4 10-13.8 10-6.8 0-12.6-4.6-14.7-10.9L3.8 33c3.3 7.7 11 13 20.2 13 10.5 0 19.4-7.1 22.2-16.7l-1.6-8.8z"
      />
      <path
        fill="#fbc02d"
        d="M9.3 25.5c-.5-1.3-.8-2.7-.8-4.1 0-1.4.3-2.8.8-4.1L3.8 11c-1.6 3.2-2.5 6.7-2.5 10.4 0 3.7.9 7.2 2.5 10.4l5.5-4.3z"
      />
      <path
        fill="#1565c0"
        d="M14.6 37.5c3.7 0 7-1.2 9.6-3.3l-7.4-5.7c-1.1.7-2.5 1.1-4.1 1.1-3.4 0-6.3-2.2-7.4-5.3L9.3 25.5C10.5 31.9 16.3 37.5 24 37.5z"
      />
    </svg>
  );
  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-screen">
          <Spin spinning={true}></Spin>
        </div>
      }
    >
      <div className="container flex justify-center items-center h-screen">
        <div className="grid grid-cols-2 place-items-center ">
        <div className="w-full bg-authBg h-screen hidden md:flex justify-center items-center ">
            <LazyLoadImage
              src={Images.authbanner}
              alt="signupimage"
              className="w-[100%] h-screen object-cover object-right"
            />
          </div>
      
          
          <div className="w-full h-screen flex justify-center items-center col-span-2 md:col-span-1">
            <div className="w-3/4 m-auto">
              <div className="font-poppins text-start text-2xl font-normal mb-8">
                <p>Login</p>
              </div>
              <Form onFinish={(val) => handleLogin(val)}>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: "Email Is Required",
                    },
                    {
                      pattern: emailPattern,
                      message: "Please Enter Valid Email",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  label={makeLabel("Email Address")}
                >
                  <Input
                    placeHolder="Email address"
                    className="authinput hover:border p-3 hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
                  />
                </Form.Item>
                <Form.Item
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Password Is Required",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  label={makeLabel("Password")}
                >
                  <Input.Password
                    className="rounded-md hover:border p-3 hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
                    placeholder="Password"
                    iconRender={(visible) => (
                      <NavLink to={"/forgetPassword"}>
                        <p
                          style={{
                            color: "#1890ff",
                            cursor: "pointer",
                            fontSize: "12px",
                          }}
                        >
                          Reset Password
                        </p>
                      </NavLink>
                    )}
                  />
                </Form.Item>

                <div className="flex items-center mt-4 ">
                  <Button
                    loading={btnLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ color: "white" }}
                    prefix="jj"
                    className="font-poppins p-5 text-white  rounded-md bg-primary w-full"
                  >
                    Log In
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default Login;
