import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div className="flex flex-col justify-center items-center h-screen">
            <div className="bg-primary text-white p-4 rounded-md">
              <p>Something Went Wromg!</p>
            </div>
            <div
              onClick={() => window.location.reload()}
              className="bg-primary mt-2 cursor-pointer text-white p-4 rounded-md"
            >
              <p>Reload</p>
            </div>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
