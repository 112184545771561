import React from "react";
import { IoIosWarning } from "react-icons/io";

const IconButton = ({ Icon = IoIosWarning, onClick = () => window.alert("onClick Handler missing"), bgColor = "#000000", bgOpacity = "0.5", iconColor = "#ffffff", size = 12 }) => {
  console.log({ Icon, onClick, bgColor, bgOpacity, iconColor, size });
  return (
    <div className={`p-2 flex flex-row justify-center items-center bg-orange rounded-full cursor-pointer`} onClick={onClick}>
      <Icon size={size} color={iconColor} />
    </div>
  );
};

export default IconButton;
