import { UploadOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Divider, Form, Image, Input, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../Advanced/advance.css";
import axios from "axios";
import { presignedApi } from "../CourseFun";
import { UPLOAD_ENDPOINT } from "../../../../ApiServices/Axios/Index";
import { dispatchtoast } from "../../../../Utils";
const Advanced = ({ setFormData, formData, setActiveTab, activeTab }) => {
  const [uploadImageProgress, setUploadImageProgress] = useState(0);
  const [uploadVideoProgress, setUploadVideoProgress] = useState(0);
  const [form] = Form.useForm();

  const handleEditorChange = (description) => {
    setFormData({ ...formData, description });
  };

  const handleUpload = async (event, type) => {
    setFormData({ ...formData, [type]: "" });
    try {
      const selectedFile = event.target.files[0];
      if (!selectedFile) {
        console.error("No file selected.");
        return;
      }
      const fileExtension = selectedFile.type.split("/").pop();
      const { preSignedUploadUrl, Key } = await presignedApi(
        `?mimetype=${fileExtension}`
      );
      console.log({ preSignedUploadUrl, Key });

      if (preSignedUploadUrl) {
        await axios.put(preSignedUploadUrl, selectedFile, {
          headers: {
            "Content-Type": selectedFile.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (type === "trailer") {
              setUploadVideoProgress(percentCompleted);
            } else {
              setUploadImageProgress(percentCompleted);
            }
          },
        });
        setFormData({ ...formData, [type]: Key });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"],
        ["link"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    },
  };
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];
  useEffect(() => {
    const toolbar = document.querySelector(".ql-toolbar");
    const editorContainer = document.querySelector(".ql-container");
    if (toolbar && editorContainer) {
      editorContainer.parentNode.insertBefore(
        toolbar,
        editorContainer.nextSibling
      );
    }
  }, []);

  const handleValuesChange = (data) => {
    console.log({ formData });
    if (
      formData.audiance.some((i) => i == "")
    ) {
      dispatchtoast("Atleats one field of audiance is required", true);
    } else if (
      formData.coverage.some((i) => i == "")
    ) {
      dispatchtoast("Atleats one field of coverage is required", true);
    } else if (
      formData.preRequisites.some((i) => i == "")
    ) {
      dispatchtoast("Atleats one field of Prerequisites is required", true);
    } 
    else {
      setActiveTab("3");
    }
    // setActiveTab("3");
    // console.log(data);
  };

  const handleChange = (e, i, field) => {
    console.log("handleChange", e, i, field);
    let list = [...formData[field]];
    list[i] = e.target.value;
    setFormData({ ...formData, [field]: list });
    console.log(formData, "formData");
  };
  const handleRemoveChange = (i, field) => {
    let list = [...formData[field]];
    list.splice(i, 1);
    setFormData({ ...formData, [field]: list });
  };

  useEffect(() => {
    if (formData && formData.coverage && formData.coverage.length) {
      form.setFieldValue("coverage",formData.coverage);
    }
    if (formData && formData.audiance && formData.audiance.length) {
      form.setFieldsValue({ audiance: [...formData.audiance] });
    }
    if (formData && formData.preRequisites && formData.preRequisites.length) {
      form.setFieldsValue({ preRequisites: [...formData.preRequisites] });
    }
    if (formData && formData.description) {
      form.setFieldsValue({ description: formData.description });
    }
  }, [formData]);

  return (
    <>
      <div className="ml-2 grid grid-cols-1 sm:grid-cols-2 ">
        <p className="text-lg font-dmsans font-semibold">
          Advanced Information
        </p>
      </div>
      <Divider />
      <Form
        form={form}
        className="bg-white p-2 font-dmsans font-medium text-xs text-gray2"
        layout="vertical"
        onFinishFailed={(err) => {
          if (err.errorFields) {
            console.log("Validation errors:", formData);
          }
        }}
        onFinish={(data) => handleValuesChange(data)}
      >
        <Row gutter={16} className="grid grid-cols-1 lg:grid-cols-2">
          <Col>
            <Form.Item
              name="thumbnail"
              label="Course Thumbnail"
              rules={[
                {
                  validator: async (_, value) => {
                    if (!formData.thumbnail) {
                      if (!value) {
                        return Promise.reject("Please Select Thumbnail Image");
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              {formData.thumbnail ? (
                <div
                  className="grid grid-cols-1 sm:grid-cols-2"
                  direction="vertical"
                  size="large"
                >
                  <label
                    htmlFor="upload-thumbnail"
                    className="rounded-none border flex justify-center items-center h-[100px] aspect-video"
                  >
                    <div className="h-[100px] aspect-video d-flex justify-center items-center relative">
                      <Image
                        src={`${UPLOAD_ENDPOINT}${formData.thumbnail}`}
                        autoPlay={false}
                        className="h-[100px] object-cover aspect-video"
                      >
                        Your browser does not support the Image tag.
                      </Image>
                    </div>
                  </label>
                  <div>
                    <p className="font-dmsans text-xs text-[#6E7485]">
                      Upload your Course Thumbnail here.
                    </p>
                    <p className="text-black font-semibold font-dmsans text-xs">
                      Important guidelines:
                    </p>
                    <p className="font-dmsans text-xs text-[#6E7485]">
                      1200*800 pixels or 12:8 Ratio. Supported Format
                    </p>
                    <p className="text-black font-semibold font-dmsans text-xs">
                      .jpg, .jpeg or .png
                    </p>
                    <Input
                      type="file"
                      id="upload-thumbnail"
                      style={{
                        opacity: "0",
                        position: "absolute",
                        zIndex: "-1",
                      }}
                      onChange={(e) => handleUpload(e, "thumbnail")}
                      accept="image/*"
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="grid grid-cols-1 sm:grid-cols-2"
                  direction="vertical"
                  size="large"
                >
                  <label
                    htmlFor="upload-thumbnail"
                    className="rounded-none border flex justify-center items-center h-[100px] aspect-video"
                  >
                    <div className="rounded-none border flex justify-center items-center h-[100px] aspect-video">
                      {uploadImageProgress > 0 && uploadImageProgress < 100 ? (
                        <div className="animate-pulse h-[100px] aspect-video">
                          <div
                            className={`bg-slate-300 h-[100px] flex flex-row justify-center items-center`}
                            style={{ width: `${uploadImageProgress}%` }}
                          >
                            <p>{`${uploadImageProgress}%`}</p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <p className="flex justify-center items-center m-2 ">
                            <UploadOutlined />
                          </p>
                          <p>Select Thumbnail</p>
                        </div>
                      )}
                    </div>
                  </label>
                  <div>
                    <p className="font-dmsans text-xs text-[#6E7485]">
                      Upload your Course Thumbnail here.
                    </p>
                    <p className="text-black font-semibold font-dmsans text-xs">
                      Important guidelines:
                    </p>
                    <p className="font-dmsans text-xs text-[#6E7485]">
                      1200*800 pixels or 12:8 Ratio. Supported Format
                    </p>
                    <p className="text-black font-semibold font-dmsans text-xs">
                      .jpg, .jpeg or .png
                    </p>
                    <Input
                      type="file"
                      id="upload-thumbnail"
                      style={{
                        opacity: "0",
                        position: "absolute",
                        zIndex: "-1",
                      }}
                      onChange={(e) => handleUpload(e, "thumbnail")}
                      accept="image/*"
                    />
                  </div>
                </div>
              )}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="trailer"
              label="Course Trailer"
              rules={[
                {
                  validator: async (_, value) => {
                    if (!formData.trailer) {
                      if (!value) {
                        return Promise.reject("Please Select Trailor Video");
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              {formData.trailer ? (
                <div
                  className="grid grid-cols-1 sm:grid-cols-2"
                  direction="vertical"
                  size="large"
                >
                  <label
                    htmlFor="upload-trailer"
                    className="rounded-none border flex justify-center items-center h-[100px] aspect-video"
                  >
                    <div className="h-[100px] aspect-video d-flex justify-center items-center relative">
                      <video
                        src={`${UPLOAD_ENDPOINT}${formData.trailer}`}
                        autoPlay={false}
                        className="h-[100px] aspect-video"
                      >
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </label>

                  <p className="font-dmsans text-xs text-[#6E7485]">
                    Students who watch a well-made promo video are 5X more
                    likely to enroll in your course. We've seen that statistic
                    go up to 10X for exceptionally awesome videos.
                  </p>
                  <Input
                    type="file"
                    id="upload-trailer"
                    style={{
                      opacity: "0",
                      position: "absolute",
                      zIndex: "-1",
                    }}
                    onChange={(e) => handleUpload(e, "trailer")}
                    accept="video/*"
                  />
                </div>
              ) : (
                <div
                  className="grid grid-cols-1 sm:grid-cols-2"
                  direction="vertical"
                  size="large"
                >
                  <label
                    htmlFor="upload-trailer"
                    className="rounded-none border flex justify-center items-center h-[100px] aspect-video"
                  >
                    <div className="rounded-none border flex justify-center items-center h-[100px] aspect-video">
                      {uploadVideoProgress > 0 && uploadVideoProgress < 100 ? (
                        <div className="animate-pulse h-[100px] aspect-video">
                          <div
                            className={`bg-slate-300 h-[100px] flex flex-row justify-center items-center`}
                            style={{ width: `${uploadVideoProgress}%` }}
                          >
                            <p>{`${uploadVideoProgress}%`}</p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <p className="flex justify-center items-center m-2 ">
                            <UploadOutlined />
                          </p>
                          <p>Select Trailer Video</p>
                        </div>
                      )}
                    </div>
                  </label>
                  <div>
                    <p className="font-dmsans text-xs text-[#6E7485]">
                      Students who watch a well-made promo video are 5X more
                      likely to enroll in your course. We've seen that statistic
                      go up to 10X for exceptionally awesome videos.
                    </p>
                    <Input
                      type="file"
                      id="upload-trailer"
                      style={{
                        opacity: "0",
                        position: "absolute",
                        zIndex: "-1",
                      }}
                      onChange={(e) => handleUpload(e, "trailer")}
                      accept="video/*"
                    />
                  </div>
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row className="grid grid-cols-1">
          <Col>
            <Form.Item
              name="description"
              label="
                Course Descriptions"
              rules={[
                {
                  required: true,
                  message: "Please Enter  Course Descriptions",
                },
              ]}
            >
              <ReactQuill
                value={formData.description}
                onChange={handleEditorChange}
                theme="snow"
                modules={modules}
                formats={formats}
                rows={8}
                placeholder="Enter Your Course Descriptions"
                className="rounded-none font-dmsans custom-placeholder text-[#8c94a3] border-red-500"
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16} className="grid grid-cols-1 mb-2">
          <Col>
            <Form.List name="coverage" initialValue={[""]}>
              {(fields, { add, remove }) => (
                <>
                  <div className="grid grid-cols-12 gap-2">
                    <p className="text-sm font-dmsans col-span-11 font-semibold text-black">
                      What you will teach in this course (1/5)
                    </p>
                    <p
                      onClick={() => {
                        if (fields.length < 5) {
                          add();
                        }
                      }}
                      className="bg-[#ffeee8] text-sm border-none cursor-pointer text-[#FF6636] p-2 flex justify-center font-dmsans font-semibold rounded-none col-span-1"
                    >
                      Add +
                    </p>
                  </div>
                  <Form.Item
                    name="coverage"
                    label="What you will teach"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "At least one coverage is required",
                    //   },
                    // ]}
                  >
                    {fields.map((field, index) => (
                      <Row key={field.key} align="middle">
                        <div className="grid grid-cols-1 w-full">
                          <Form.Item
                            {...field}
                            name={[field.name, "input"]}
                            fieldKey={[field.fieldKey, "input"]}
                            label={
                              <span className="text-xs font-semibold col-span-1 font-dmsans">
                                0{index + 1}
                              </span>
                            }
                          >
                            <div className="grid grid-cols-12 gap-2">
                              <Input
                                onChange={(e) =>
                                  handleChange(e, index, "coverage")
                                }
                                value={formData.coverage[index]}
                                suffix={
                                  <span className="text-sm text-[#4E5566] font-normal font-dmsans">
                                    0/120
                                  </span>
                                }
                                placeholder="What you will teach in this course..."
                                type="text"
                                className="rounded-none col-span-11"
                              />
                              <p
                                onClick={() => {
                                  if (fields.length > 1) {
                                    remove(field.name);
                                    handleRemoveChange(index, "coverage");
                                  }
                                }}
                                className="col-span-1 bg-[#ffeee8] text-sm border-none cursor-pointer text-[#FF6636] p-2 flex justify-center font-dmsans font-semibold rounded-none"
                              >
                                Remove -
                              </p>
                            </div>
                          </Form.Item>
                        </div>
                      </Row>
                    ))}
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        <Row gutter={16} className="grid grid-cols-1 mb-2">
          <Col>
            <Form.List name="audiance" initialValue={[""]}>
              {(fields, { add, remove }) => (
                <>
                  <div className="grid grid-cols-12 gap-2">
                    <p className="text-sm font-dmsans col-span-11 font-semibold text-black">
                      Target Audience (1/5)
                    </p>
                    <p
                      onClick={() => {
                        if (fields.length < 5) {
                          add();
                        }
                      }}
                      className="bg-[#ffeee8] text-sm border-none cursor-pointer text-[#FF6636] p-2 flex justify-center font-dmsans font-semibold rounded-none col-span-1"
                    >
                      Add +
                    </p>
                  </div>
                  <Form.Item
                    name="audiance"
                    label="Target Audience"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "At least one audience is required",
                    //   },
                    // ]}
                  >
                    {fields.map((field, index) => (
                      <Row key={field.key} align="middle">
                        <div className="grid grid-cols-1 w-full">
                          <Form.Item
                            {...field}
                            name={[field.name, "input"]}
                            fieldKey={[field.fieldKey, "input"]}
                            label={
                              <span className="text-xs font-semibold col-span-1 font-dmsans">
                                0{index + 1}
                              </span>
                            }
                          >
                            <div className="grid grid-cols-12 gap-2">
                              <Input
                                onChange={(e) =>
                                  handleChange(e, index, "audiance")
                                }
                                value={formData.audiance[index]}
                                suffix={
                                  <span className="text-sm text-[#4E5566] font-normal font-dmsans">
                                    0/120
                                  </span>
                                }
                                placeholder="Who this course is for..."
                                type="text"
                                className="rounded-none col-span-11"
                              />
                              <p
                                onClick={() => {
                                  if (fields.length > 1) {
                                    remove(field.name);
                                    handleRemoveChange(index, "audiance");
                                  }
                                }}
                                className="col-span-1 bg-[#ffeee8] text-sm border-none cursor-pointer text-[#FF6636] p-2 flex justify-center font-dmsans font-semibold rounded-none"
                              >
                                Remove -
                              </p>
                            </div>
                          </Form.Item>
                        </div>
                      </Row>
                    ))}
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        <Row gutter={16} className="grid grid-cols-1 mb-2">
          <Col>
            <Form.List name="preRequisites" initialValue={[""]}>
              {(fields, { add, remove }) => (
                <>
                  <div className="grid grid-cols-12 gap-2">
                    <p className="text-sm font-dmsans col-span-11 font-semibold text-black">
                      Prerequisites (1/5)
                    </p>
                    <p
                      onClick={() => {
                        if (fields.length < 5) {
                          add();
                        }
                      }}
                      className="bg-[#ffeee8] text-sm border-none cursor-pointer text-[#FF6636] p-2 flex justify-center font-dmsans font-semibold rounded-none col-span-1"
                    >
                      Add +
                    </p>
                  </div>
                  <Form.Item
                    name="preRequisites"
                    label="Prerequisites"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "At least one prerequisite is required",
                    //   },
                    // ]}
                  >
                    {fields.map((field, index) => (
                      <Row key={field.key} align="middle">
                        <div className="grid grid-cols-1 w-full">
                          <Form.Item
                            {...field}
                            name={[field.name, "input"]}
                            fieldKey={[field.fieldKey, "input"]}
                            label={
                              <span className="text-xs font-semibold col-span-1 font-dmsans">
                                0{index + 1}
                              </span>
                            }
                          >
                            <div className="grid grid-cols-12 gap-2">
                              <Input
                                onChange={(e) =>
                                  handleChange(e, index, "preRequisites")
                                }
                                value={formData.preRequisites[index]}
                                suffix={
                                  <span className="text-sm text-[#4E5566] font-normal font-dmsans">
                                    0/120
                                  </span>
                                }
                                placeholder="What you should know before taking this course..."
                                type="text"
                                className="rounded-none col-span-11"
                              />
                              <p
                                onClick={() => {
                                  if (fields.length > 1) {
                                    remove(field.name);
                                    handleRemoveChange(index, "preRequisites");
                                  }
                                }}
                                className="col-span-1 bg-[#ffeee8] text-sm border-none cursor-pointer text-[#FF6636] p-2 flex justify-center font-dmsans font-semibold rounded-none"
                              >
                                Remove -
                              </p>
                            </div>
                          </Form.Item>
                        </div>
                      </Row>
                    ))}
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        <Row gutter={16} className="flex justify-between p-6">
          <Form.Item>
            <Button
              className="rounded-none p-[0px_32px_0px_32px]   text-sm font-dmsans font-semibold text-gray2 "
              size="large"
              htmlType="onSubmit"
              onClick={() => setActiveTab("1")}
            >
              Previous
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              className=" rounded-none p-[0px_32px_0px_32px] hover:bg-black text-white font-dmsans font-semibold  bg-orange text-xs "
              size="large"
              htmlType="onSubmit"
            >
              Save & Next
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </>
  );
};

export default Advanced;
