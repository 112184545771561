import { Get, getAPIUrl, Post } from "../../../ApiServices/apiMethod";
import {  HomePageService } from "../../../ApiServices/Axios/Index";
import { CarousalEndpoint } from "./CarousalEndpoint";

export const getCarousal = (param = "") => {
  const url = getAPIUrl(CarousalEndpoint.getCarousal, param);
  return Get(HomePageService, url, true);
};
export const addCarousal = (data, param = "") => {
  const url = getAPIUrl(CarousalEndpoint.addCarousal, param);
  return Post(HomePageService, url, data, true);
};
